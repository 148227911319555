import Modal from 'com/widgets/Modal';

import Select from 'com/ui/Select';
import Input from 'com/ui/Input';
import Switch from 'com/ui/Switch';
import DataTable from 'com/widgets/DataTable';

import './style.css';

const EditAssociateRatesModal = (props) => {
	const viewFullRates = () => {
		const win = window.open(`/firm-management/${props.data.firm_id}/rates`, '_blank');
	};

	const modalActions = [
		{ primary: false, label: 'View Full Rates', action: viewFullRates, theme: 'azami-ghost' },
		{ primary: true, label: 'Save', action: props.submitRateUpdate, theme: 'azami-blue' },
	];

	const headers = [
		{ title: 'Service', field: 'name', type: 'string', sort: 'none', sortable: false },
		{ title: 'availability', field: 'level', type: 'string', sort: 'none', sortable: false },
		{ title: 'Filing fee', field: 'fees', type: 'string', sort: 'none', sortable: false },
		{ title: 'translation fee', field: 'region', type: 'string', sort: 'none', sortable: false },
		{ title: 'Currency', field: 'total_fees', type: 'string', sort: 'none', sortable: false },
	];

	const rate_translation_type = [
		{ label: 'Per Word', value: 'WORD' },
		{ label: 'Per Page', value: 'PAGE' },
	];

	return (
		<Modal title="" closeHandler={props.closeHandler} footerActions={modalActions}>
			<div className="update-rate-modal">
				<div className="update-rate-modal__body">
					<DataTable fixedHeader={true} columns={headers}>
						<tr className="update-rate-modal__body-row">
							<DataTable.Cell>{props.data.service}</DataTable.Cell>
							<DataTable.Cell>
								<Switch theme="blurple" name={'rate_availability'} value={props.data.available} onChange={props.handleChange} />
							</DataTable.Cell>
							<DataTable.Cell>
								<Input
									customClassName={'update-input professional-fee'}
									type="number"
									min="0"
									value={props.data.rate_professional}
									name="rate_professional"
									onChange={props.handleChange}
								/>
							</DataTable.Cell>
							<DataTable.Cell>
								<Select
									name="rate_translation_type"
									options={rate_translation_type}
									value={props.data.rate_translation_type}
									placeholder="Select Currency"
									onChange={props.handleChange}
								/>
								<Input
									customClassName={'update-input translation-fee'}
									type="number"
									min="0"
									value={props.data.rate_translation}
									name="rate_translation"
									onChange={props.handleChange}
								/>
							</DataTable.Cell>
							<DataTable.Cell>
								<Select name="currency" options={props.currencies} value={props.data.currency} placeholder="Select Currency" onChange={props.handleChange} />
							</DataTable.Cell>
						</tr>
					</DataTable>
				</div>
			</div>
		</Modal>
	);
};

export default EditAssociateRatesModal;
