import React from 'react';
import { useDispatch } from 'react-redux';

import { getError } from 'util/errors';
import { setLoggedIn, setUserData, setUserAgentAssociations } from 'redux/ducks/auth';
import Auth from 'services/rest/auth';
import EventLogger from 'services/rest/event_logger';
import jwt from 'services/jwt';
import FirmAgents from 'services/rest/firm_agents';

import withDomainAuthTemplate from 'com/hoc/withDomainAuthTemplate';
import LoginAzami from './LoginAzami';
import LoginIPeer from './LoginIPeer';

import { PAGE_TITLE } from 'data/constants';

import useTitle from 'services/hooks/useTitle';

import LogRocket from 'logrocket';

import './style.css';

const LoginTemplate = withDomainAuthTemplate(LoginAzami, LoginIPeer);

const Login = () => {
	const dispatch = useDispatch();
	useTitle(PAGE_TITLE.LOGIN);

	const initialState = {
		email: '',
		password: '',
	};

	const handleSubmit = async (fields, { setStatus }) => {
		try {
			let grctoken = await recaptchaToken();
			let res = await Auth.Login({ username: fields.email, password: fields.password, grct: grctoken });
			window.localStorage.setItem('token', res.token);
			const user = jwt.parseJWT(res.token);
			// set logrocket ID data for easy tracking of the user
			LogRocket.identify(user.uid, {
				name: user.name,
				email: user.email,
				role: user.role,
			});
			// set user data in redux
			dispatch(setUserData(user));
			dispatch(setLoggedIn(true));
			if (user.role.includes('saas_user')) {
				let agentAssociations = await FirmAgents.GetAssociations(user.fid);
				dispatch(setUserAgentAssociations({ agentOfFirms: agentAssociations.firm_clients, clientOfFirms: agentAssociations.firm_agents }));
			} else {
				dispatch(setUserAgentAssociations({ agentOfFirms: [], clientOfFirms: [] }));
			}
			EventLogger.EventLogger({ event: `Logged In`, data: { user: user } });
		} catch (err) {
			console.log(err);
			setStatus(getError(err.code));
		}
	};

	const recaptchaToken = async () => {
		return new Promise((success, fail) => {
			if (!window.grecaptcha) return success('');
			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'submit' }).then((token) => {
					success(token);
				});
			});
		});
	};

	return <LoginTemplate initialState={initialState} handleSubmit={handleSubmit} />;
};

export default Login;
