import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Input from 'com/ui/Input';
import Switch from 'com/ui/Switch';
import Select from 'com/ui/Select';
import IconButton from 'com/ui/IconButton';
import { RoleBox } from 'com/util/RoleBox';

import Modal from 'com/widgets/Modal';

import { INTERNAL_ROLES, SERVICE_KEYS, DOMAINS } from 'data/constants';

import ARROW_ANGLE_DOWN_BLURPLE from 'assets/images/icons/ico_angle_arrow_down_blurple.svg';
import ARROW_ANGLE_UP_BLURPLE from 'assets/images/icons/ico_angle_arrow_blurple.svg';
import ARROW_ANGLE_UP_WHITE from 'assets/images/icons/ico_angle_arrow_white.svg';
import ARROW_ANGLE_DOWN_WHITE from 'assets/images/icons/ico_angle_arrow_down_white.svg';
import ICON_INFO from 'assets/images/icons/ico_info_magenta.svg';

import Settings from 'services/rest/settings';
import { getDomain } from 'services/strings';

import './style.css';

import ICON_BACK from '../../../assets/images/icons/ico_back_white.svg';

const RatesList = (props) => {
	const user = useSelector((state) => state.auth.user);

	const [rates, setRates] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	const [services, setServices] = useState([]);
	const [regions, setRegions] = useState([]);
	const [clients, setClients] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [rowKey, setRowKey] = useState('');

	useEffect(async () => {
		try {
			let [dataExchangeRates, dataServices] = await Promise.all([Settings.GetExchangeRates(), Settings.GetServices()]);
			dataExchangeRates.sort((a, b) =>
				a.code > b.code ? 1
				: b.code > a.code ? -1
				: 0,
			);
			setCurrencies(dataExchangeRates);
			setServices(dataServices);
			const azamiClient = {
				client_id: props.data.id,
				client_name: 'Azami rates',
			};

			let oonClients =
				Array.isArray(props.clients) ?
					props.clients.map((o) => {
						return {
							client_id: o.associate_id,
							client_name: o.associate_name,
						};
					})
				:	[];

			// ensure saas user only sees their rates and agents rates, but doesnt see azami rates
			if (user.role.includes('saas_user')) {
				return setClients([...oonClients]);
			}

			// everyone else sees azami rates and their rates
			if (!user.role.includes('saas_user')) {
				return setClients([azamiClient, ...oonClients]);
			}
		} catch (err) {
			console.log(err);
		}
	}, [props.data.id, props.clients]);

	useEffect(() => {
		setRates([...(props.data.rates ? props.data.rates : []), ...(props.data.firm_rates ? props.data.firm_rates : [])]);
		setRegions(props.data.regions);
	}, [props.data]);

	const onClickCloseModal = () => {
		setRowKey('');
		setIsModalOpen(false);
	};
	const onOpenModal = (key) => {
		setRowKey(key);
		setIsModalOpen(true);
	};

	const onClickContinue = () => {
		setIsModalOpen(false);
	};

	const modalFooterActions = [
		{ primary: false, label: 'Cancel', action: onClickCloseModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Continue', action: onClickContinue, theme: 'azami-blue' },
	];

	return (
		<div className="rates-list">
			{clients.map((oa, i) => {
				return (
					<RatesClientSection
						data={oa}
						regions={regions}
						services={services}
						currencies={currencies}
						rates={rates}
						onChange={props.onChange}
						key={i}
						fid={props.data.id}
						onOpenModal={onOpenModal}
						onClickContinue={onClickContinue}
						rowKey={rowKey}
						firmCurrency={props.firmCurrency}
					/>
				);
			})}
			{isModalOpen && (
				<Modal title="Warning" closeHandler={onClickCloseModal} footerActions={modalFooterActions}>
					<div className="rates-list__modal">
						<span>Are you sure you want to make the change?</span>
						<p>The rates have already been specified by the associate.</p>
					</div>
				</Modal>
			)}
		</div>
	);
};

const RatesClientSection = (props) => {
	const user = useSelector((state) => state.auth.user);
	const [active, setActive] = useState(props.data && props.data.client_id === user.fid ? true : false);
	const [arrow, setArrow] = useState(ARROW_ANGLE_DOWN_WHITE);
	const [showClientNameHeader, setShowClientNameHeader] = useState(false);
	const { id } = useParams();
	const domain = getDomain();

	useEffect(() => {
		setArrow(active ? ARROW_ANGLE_UP_WHITE : ARROW_ANGLE_DOWN_WHITE);
	}, [active]);

	const showHide = () => setActive(!active);

	useEffect(() => {
		setShowClientNameHeader(user.fid === id && DOMAINS.IPEER.includes(btoa(domain)));
	}, [id]);

	return (
		<div className="rates-list-item">
			{showClientNameHeader && (
				<div className="rates-list-client__header" onClick={showHide}>
					<img src={arrow} />
					<span className="rates-list-client__header__title">{props.data?.client_name}</span>
				</div>
			)}
			{(active || !showClientNameHeader) &&
				props.regions &&
				props.regions.map((r) => (
					<RatesCountrySection
						key={r.region_code}
						services={props.services}
						currencies={props.currencies}
						region={r}
						rates={props.rates.filter((rt) => rt.region_id === r.region_id)}
						onChange={props.onChange}
						revertRate={props.revertRate}
						clientID={props.data.client_id}
						fid={props.fid}
						onOpenModal={props.onOpenModal}
						onClickContinue={props.onClickContinue}
						rowKey={props.rowKey}
						singleRegion={props.regions.length === 1 ? true : false}
						firmCurrency={props.currencies.find((c) => c.currency_id === props.firmCurrency)}
					/>
				))}
		</div>
	);
};

const RatesCountrySection = (props) => {
	const user = useSelector((state) => state.auth.user);
	const [active, setActive] = useState(props.clientID === user.fid && props.singleRegion ? true : false);
	const [arrow, setArrow] = useState(ARROW_ANGLE_DOWN_BLURPLE);
	const [regionCurrency, setRegionCurrency] = useState('');

	const translationFeeOptions = [
		{ value: 'WORD', label: 'Per Word' },
		{ value: 'PAGE', label: 'Per Page' },
	];

	useEffect(() => {
		setArrow(active ? ARROW_ANGLE_UP_BLURPLE : ARROW_ANGLE_DOWN_BLURPLE);
	}, [active]);

	const showHide = () => {
		setActive(!active);
	};

	const preventDefault = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const nameGenerator = (serviceID, name) => {
		return `${name}__${props.region.region_id}__${serviceID}__${props.clientID}`;
	};

	const fieldChange = (e) => {
		let args = e.target.name.split('__');
		props.onChange(args[0], args[1], args[2], args[3], e.target.value, e.target.closest('tr').id);
	};

	const onCurrencyChange = (e) => {
		setRegionCurrency(e.target.value);
		let args = e.target.name.split('__');
		props.onChange(args[0], args[1], args[2], args[3], e.target.value);
	};

	const getRate = (serviceID) => {
		let userRate = null;
		let firmRate = null;
		//  get user settings
		for (let rate of props.rates) {
			if (rate.object_type === 'USER' && rate.service_id === serviceID && rate.client_id === props.clientID) {
				userRate = rate;
			}
			if (rate.object_type === 'FIRM' && rate.service_id === serviceID && rate.client_id === props.clientID) {
				firmRate = rate;
			}
		}
		if (userRate) return userRate;
		if (firmRate) return firmRate;
		return {
			id: false,
			available: false,
			rate_professional: 0,
			rate_translation_type: 'WORD',
			rate_translation: 0,
			currency: props?.firmCurrency.code || 'USD',
			object_id: 'USER',
		};
	};

	const isExternalUser = ['user', 'member', 'member_assistant'].includes(user.role[0]);

	const confirmedByTypes = [
		{
			value: '',
			label: '',
		},
		{
			value: 'CLIENT',
			label: 'Client',
			disabled: true,
		},
		{
			value: 'AGENT',
			label: 'Agent',
			disabled: false,
		},
	];

	const onOpenModal = (e, rate) => {
		let key = e.target.closest('tr').dataset.key;
		if (rate.rate_confirmed_by === 'AGENT' && props.rowKey !== key && user.fid === props.clientID && props.fid !== user.fid) {
			e.preventDefault();
			props.onOpenModal(key);
		}
	};

	const isAgentCondition = (rate) => {
		return user.fid === props.fid && props.fid !== props.clientID && rate.rate_confirmed_by === 'CLIENT';
	};

	const isAzamiEmployee = () => {
		return user.fid !== props.fid && !isExternalUser && user.fid !== props.clientID;
	};

	return (
		<div className={`rates-list-item ${active ? 'active' : ''}`}>
			<div className="rates-list-item__header" onClick={showHide}>
				<img src={arrow} />
				<span className="rates-list-item__header__title">{props.region.region_name}</span>
				<select
					className={props.region.is_archived ? 'grey' : ''}
					disabled={isExternalUser}
					value={regionCurrency ? regionCurrency : props?.firmCurrency?.code || 'USD'}
					name={nameGenerator('service', 'region_currency')}
					onClick={preventDefault}
					onChange={onCurrencyChange}
				>
					{[{ code: '' }, ...props.currencies].map((c, i) => (
						<option value={c.code} key={i}>
							{c.code}
						</option>
					))}
				</select>
			</div>
			{active ?
				<div className="rates-list-item__body">
					{props.region.is_archived ?
						<div className="rates-list-item__body__archived">
							<img src={ICON_INFO} alt="" />
							<span>Since the region is archived, you can't edit it. Unarchive can be done on the Expertise & Regions tab.</span>
						</div>
					:	null}
					<table className={props.region.is_archived ? 'rates-list-item__body__table__grey' : ''}>
						<thead>
							<tr>
								<th>Service</th>
								<th>Confirmed by</th>
								<RoleBox roles={['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager', 'saas_user']}>
									<th>Availability</th>
								</RoleBox>
								<th>Filing fee</th>
								<th>Translation fee</th>
								<th>Currency</th>
								{props.revertRate ?
									<th></th>
								:	null}
							</tr>
						</thead>
						<tbody>
							{
								// if the serving region is translation, we are rendering only the translations service
								(props.region.is_translation ? props.services.filter((s) => s.key === SERVICE_KEYS.TRANSLATIONS) : props.services).map((srv) => {
									let r = getRate(srv.id);
									// one of the rates should be over 0 to show the service
									let showService = r.rate_professional || r.rate_translation ? true : false;
									if (isExternalUser && showService) {
										return (
											<tr id={r.id} key={`${props.region.region_id}_${srv.id}`}>
												<td>{srv.name}</td>
												<td>
													<Select
														disabled={true}
														options={confirmedByTypes}
														name={nameGenerator(srv.id, 'rate_confirmed_by')}
														onChange={fieldChange}
														value={r.rate_confirmed_by}
													/>
												</td>
												<RoleBox roles={INTERNAL_ROLES}>
													<td>
														<Switch
															disabled={props.region.is_archived ? true : false}
															forRoles={['admin', 'sales', 'sales_manager']}
															name={nameGenerator(srv.id, 'available')}
															onChange={fieldChange}
															value={r.available}
															theme="blurple"
														/>
													</td>
												</RoleBox>
												<td>
													<Input
														disabled={props.region.is_archived ? true : false}
														forRoles={['admin', 'sales', 'sales_manager', '$clientOfFirm']}
														name={nameGenerator(srv.id, 'rate_professional')}
														onChange={fieldChange}
														customClassName="rates-list-item__custom-input"
														type="number"
														value={r.rate_professional}
														privateField={true}
													/>
												</td>
												<td>
													<div className="rates-list-item__body__cell-flex-wrapper">
														<Select
															disabled={props.region.is_archived ? true : false}
															forRoles={['admin', 'sales', 'sales_manager', '$clientOfFirm']}
															name={nameGenerator(srv.id, 'rate_translation_type')}
															options={translationFeeOptions}
															customClassName="rates-list-item__custom-select-half"
															value={r.rate_translation_type}
															onChange={fieldChange}
														/>
														<Input
															disabled={props.region.is_archived ? true : false}
															forRoles={['admin', 'sales', 'sales_manager', '$clientOfFirm']}
															name={nameGenerator(srv.id, 'rate_translation')}
															onChange={fieldChange}
															customClassName="rates-list-item__custom-input-half"
															type="number"
															value={r.rate_translation}
															privateField={true}
														/>
													</div>
												</td>
												<td>
													<Select
														disabled={props.region.is_archived ? true : false}
														forRoles={['admin']}
														options={props.currencies.map((cr) => {
															return { value: cr.code, label: cr.code };
														})}
														customClassName="rates-list-item__custom-select"
														name={nameGenerator(srv.id, 'currency')}
														value={r.currency}
														onChange={fieldChange}
													/>
												</td>
												{props.revertRate ?
													<RoleBox roles={INTERNAL_ROLES}>
														<td>
															<IconButton
																forRoles={['admin']}
																size="small"
																title="Go back to default rate"
																icon={ICON_BACK}
																onClick={fieldChange}
																name={nameGenerator(srv.id, 'revert')}
																disabled={r.object_type !== 'USER'}
															/>
														</td>
													</RoleBox>
												:	null}
											</tr>
										);
									}
									if (!isExternalUser) {
										return (
											<tr id={r.id} key={`${props.region.region_id}_${srv.id}`} data-key={`${props.region.region_id}_${srv.id}`}>
												<td>{srv.name}</td>
												<td>
													<Select
														disabled={
															isAzamiEmployee() ? false
															: isAgentCondition(r) ?
																false
															:	true
														}
														options={confirmedByTypes}
														name={nameGenerator(srv.id, 'rate_confirmed_by')}
														onChange={fieldChange}
														value={r.rate_confirmed_by}
													/>
												</td>
												<RoleBox roles={[...INTERNAL_ROLES, 'saas_user']}>
													<td>
														<Switch
															onClickParent={(e) => onOpenModal(e, r)}
															disabled={props.region.is_archived ? true : false}
															forRoles={['admin', 'sales', 'sales_manager', 'saas_user']}
															name={nameGenerator(srv.id, 'available')}
															onChange={fieldChange}
															value={r.available}
															theme="blurple"
														/>
													</td>
												</RoleBox>
												<td>
													<Input
														onMouseDown={(e) => onOpenModal(e, r)}
														disabled={props.region.is_archived ? true : false}
														forRoles={['admin', 'sales', 'sales_manager', 'saas_user']}
														name={nameGenerator(srv.id, 'rate_professional')}
														onChange={fieldChange}
														customClassName="rates-list-item__custom-input"
														type="number"
														value={r.rate_professional}
														privateField={true}
													/>
												</td>
												<td>
													<div className="rates-list-item__body__cell-flex-wrapper">
														<Select
															onMouseDown={(e) => onOpenModal(e, r)}
															disabled={props.region.is_archived ? true : false}
															forRoles={['admin', 'sales', 'sales_manager', 'saas_user']}
															name={nameGenerator(srv.id, 'rate_translation_type')}
															options={translationFeeOptions}
															customClassName="rates-list-item__custom-select-half"
															value={r.rate_translation_type}
															onChange={fieldChange}
														/>
														<Input
															onMouseDown={(e) => onOpenModal(e, r)}
															disabled={props.region.is_archived ? true : false}
															forRoles={['admin', 'sales', 'sales_manager', 'saas_user']}
															name={nameGenerator(srv.id, 'rate_translation')}
															onChange={fieldChange}
															customClassName="rates-list-item__custom-input-half"
															type="number"
															value={r.rate_translation}
															privateField={true}
														/>
													</div>
												</td>
												<td>
													<Select
														onMouseDown={(e) => onOpenModal(e, r)}
														disabled={props.region.is_archived ? true : false}
														forRoles={['admin', 'saas_user']}
														options={props.currencies.map((cr) => {
															return { value: cr.code, label: cr.code };
														})}
														customClassName="rates-list-item__custom-select"
														name={nameGenerator(srv.id, 'currency')}
														value={r.currency}
														onChange={fieldChange}
													/>
												</td>
												{props.revertRate ?
													<RoleBox roles={[...INTERNAL_ROLES, 'saas_user']}>
														<td>
															<IconButton
																forRoles={['admin', 'saas_user']}
																size="small"
																title="Go back to default rate"
																icon={ICON_BACK}
																onClick={fieldChange}
																name={nameGenerator(srv.id, 'revert')}
																disabled={r.object_type !== 'USER'}
															/>
														</td>
													</RoleBox>
												:	null}
											</tr>
										);
									}
								})
							}
						</tbody>
					</table>
				</div>
			:	null}
		</div>
	);
};

export default RatesList;
