import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Input from 'com/ui/Input';
import Select from 'com/ui/Select';
import DataTable, { Paging } from 'com/widgets/DataTable';
import LetterBadge from 'com/ui/LetterBadge';
import BooleanBadge from 'com/ui/BooleanBadge';
import CustomizeEstimateRule from 'com/widgets/CustomizeEstimateRule';
import EstimatorRulesPreview from 'com/widgets/EstimatorRulesPreview';

import { PAGE_TITLE } from 'data/constants';

import Settings from 'services/rest/settings';
import Estimates from 'services/rest/estimates';
import Quotes from 'services/rest/quotes';
import PricingLevel from 'services/rest/pricing_levels';

import useTitle from 'services/hooks/useTitle';
import useNotification from 'services/hooks/use_notification';
import useFilterStorage from 'services/hooks/useFilterStorage';

import './style.css';

const QuoteDetailsViewEstimatesRules = (props) => {
	useTitle(PAGE_TITLE.QUOTE_DETAILS);

	const { id } = useParams();

	const tableHeadersInit = [
		{ title: 'Rule Name', field: 'name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Regions', field: 'flat_regions', type: 'string', sort: 'none', sortable: false },
		{ title: 'Fee', field: 'fee_type', type: 'string', sort: 'none', sortable: true },
		{ title: 'Pricing Level', field: 'use_pricing_level', type: 'boolean', sort: 'none', sortable: true },
		{ title: '', field: 'associate_firm_name', type: 'string', sort: 'none', sortable: false },
	];

	const feeTypes = [
		{ label: 'All', value: '' },
		{ label: 'Filing', value: 'PROFESSIONAL' },
		{ label: 'Official', value: 'OFFICIAL' },
		{ label: 'Translation', value: 'TRANSLATION' },
	];

	const badgeColors = {
		PROFESSIONAL: '#026EB3',
		OFFICIAL: '#10B5B5',
		TRANSLATION: '#BD2FEE',
	};

	const filterInit = {
		name: '',
		region: '',
		fee_type: '',
		orderBy: [],
	};

	const quoteDataInit = {
		application_number: '',
		client_ref_number: '',
		publication_number: '',
		service_id: '',
		priority_type: null,
		application_technologies: [],
		regions: [],
		client_id: '',
		application_title: '',
		applicant_name: '',
		earliest_priority_date: null,
		number_of_priorities: 0,
		intl_filing_date: null,
		chapter_two_demand: false,
		claiming_priority: false,
		china_extension_fee: false,
		number_of_words: 0,
		number_of_pages: 0,
		number_of_claims: 0,
		number_of_independent_claims: 0,
		number_of_page_drawings: 0,
		number_of_page_sequences: 0,
		number_of_words_in_claims: 0,
		number_of_classes: 1,
		number_of_embodiments: 0,
		entity_size: null,
		location_of_search: 'NO_REPORT',
		application_language: '',
		documents: [],
		calculated_pricing_level: false,
		additional_info: '',
		internal_quote_notes: '',
		custom_pdf_notes: '',
		agree_to_terms: false,
		quote_details_header_error: false,
		client_firm_id: '',
		instruct_additional_applications: false,
	};

	const [regions, setRegions] = useState([]);
	const [rules, setRules] = useState([]);
	const [filters, setFilters] = useState(filterInit);
	const [showCustomizeRule, setShowCustomizeRule] = useState(false);
	const [ruleToCustomize, setRuleToCustomize] = useState('');
	const [ruleToPreview, setRuleToPreview] = useState('');
	const [showRulePreview, setShowRulePreview] = useState(false);
	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [paging, setPaging] = useState({ total_rows: 0, page: 1, limit: Paging.PAGE_SIZE[0].value });
	const [pageChange, setPageChange] = useState(1);
	const [pricingLevels, setPricingLevels] = useState([]);
	const sendNotification = useNotification();
	const [quoteData, setQuoteData] = useState(quoteDataInit);
	const { getFilterValue, setFilterValue } = useFilterStorage();

	useEffect(() => {
		(async () => {
			try {
				let f = getFilterValue();
				if (f) setFilters(f);
				let [regionData, quote, pl] = await Promise.all([Settings.GetRegions(), Quotes.GetOne(id), PricingLevel.GetAll({ show_is_archived: '0' })]);
				setRegions([
					{ label: 'All', value: '' },
					...regionData.map((c) => {
						return { ...c, label: c.name, value: c.id };
					}),
				]);
				setQuoteData(quote);
				setPricingLevels(pl);
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				let filter = {
					name: filters.name,
					region: filters.region,
					fee_type: filters.fee_type,
					order_by: '',
					page: paging.page,
					limit: paging.limit,
				};
				if (!!filters.orderBy) {
					filter.order_by = filters.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(',');
				}
				let rules = await Estimates.GetEstimateRulesForQuote(id, filter);
				setRules(rules.data);
				setPaging({ ...paging, total_rows: rules.total_rows });
			} catch (err) {
				console.log(err);
			}
		})();
	}, [filters, showCustomizeRule, pageChange]);

	const idsToNames = (ids, array) => {
		if (ids && ids.length > 0) {
			return ids.map((i) => {
				return array.find((a) => a.id == i)?.name;
			});
		}
		return [];
	};

	const onFilterChange = (e) => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value,
		});
		setPageChange(pageChange + 1);
		setFilterValue({
			...filters,
			[e.target.name]: e.target.value,
		});
	};

	const openCustomizeRulePopup = async (e) => {
		e.stopPropagation();
		let rule_id = e.currentTarget.dataset.id;
		setRuleToCustomize(rule_id);
		setShowCustomizeRule(true);
	};

	const closeCustomizeRulePopup = () => {
		setShowCustomizeRule(false);
	};

	const closeRulePreview = () => {
		setRuleToPreview('');
		setShowRulePreview(false);
	};

	const openRulePreview = async (e) => {
		let rule_id = e.currentTarget.dataset.id;
		let rule = await Estimates.GetOneRulesByID(rule_id);
		setRuleToPreview(rule);
		setShowRulePreview(true);
	};

	const rulePreviewActions = [
		{ theme: 'lightblue', label: 'Close', action: closeRulePreview, condition: null },
		{ theme: 'light', label: 'Customize Rule', action: openCustomizeRulePopup, condition: null },
	];

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filters.orderBy.filter((r) => r.field === col).length === 0) {
						setFilters({
							...filters,
							orderBy: [{ field: col, direction: h.sort }, ...filters.orderBy],
						});
					} else {
						setFilters({
							...filters,
							orderBy: [{ field: col, direction: h.sort }, ...filters.orderBy.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
		setPageChange(pageChange + 1);
	};

	const getCountryCode = (id) => {
		let region = regions.find((r) => r.id == id);
		return region?.code;
	};

	const getCountryName = (id) => {
		let region = regions.find((r) => r.id == id);
		return region?.name || '';
	};

	const pagingChange = (p, l) => {
		setPaging({ ...paging, page: p, limit: l });
		setPageChange(pageChange + 1);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const regionFilterData = (quote_data, regions_data) => {
		let regions = [];
		if (quote_data && regions_data.length > 0) {
			for (let i in quote_data.regions) {
				let region = regions_data.find((r) => r.id === quote_data.regions[i]);
				if (region) {
					regions.push({ label: region.name, value: region.id });
				}
			}
		}

		let result = regions.toSorted();

		return [{ label: 'All', value: '' }].concat(result);
	};

	return (
		<div className="quote-details-view-estimator-rules">
			<div className="quote-details-view-estimator-rules__section-header filters">
				<Input label="Search by name" name="name" value={filters.name} onChange={onFilterChange} />
				<Select label="Region" options={regionFilterData(quoteData, regions)} name="region" value={filters.region} onChange={onFilterChange} />
				<Select label="Fee Type" options={feeTypes} name="fee_type" value={filters.fee_type} onChange={onFilterChange} />
			</div>
			<div className="quote-details-view-estimator-rules__section-body">
				<EstimatorRulesPreview show={showRulePreview} rule={ruleToPreview} footerActions={rulePreviewActions} closeHandlrer={closeRulePreview} />
				<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort}>
					{rules.map((r, i) => {
						return (
							<tr key={r.id} onClick={openRulePreview} data-id={r.id}>
								<DataTable.Cell>{r.name}</DataTable.Cell>
								<DataTable.RuleRegionCell
									countryCode={getCountryCode(r.flat_regions[0])}
									cellTitle={idsToNames(r.flat_regions, regions).join(', ')}
									countryCount={r.flat_regions.length - 1}
								>
									{getCountryName(r.flat_regions[0])}
								</DataTable.RuleRegionCell>
								<DataTable.Cell>
									<LetterBadge color={badgeColors[r.fee_type]}>{r.fee_type}</LetterBadge>
								</DataTable.Cell>
								<DataTable.Cell>
									<BooleanBadge value={!!r.use_pricing_level} />
								</DataTable.Cell>
								<DataTable.Cell>
									<button className="quote-details-view-estimator-rules__table-edit-btn" data-id={r.id} onClick={openCustomizeRulePopup}></button>
								</DataTable.Cell>
							</tr>
						);
					})}
				</DataTable>
				<Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange} />
			</div>
			{showCustomizeRule ?
				<CustomizeEstimateRule
					title="Edit Custom Rule"
					closeHandler={closeCustomizeRulePopup}
					isEdit={true}
					ruleToCustomize={ruleToCustomize}
					object_id={id}
					object_level={'CASE'}
					sendNotification={sendNotification}
					pricingLevels={pricingLevels}
				></CustomizeEstimateRule>
			:	null}
		</div>
	);
};

export default QuoteDetailsViewEstimatesRules;
