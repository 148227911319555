import { useEffect, useState } from 'react';

import Modal from 'com/widgets/Modal';
import Input from 'com/ui/Input';
import LoaderOverlay from 'com/ui/LoaderOverlay';
import DataTable from 'com/widgets/DataTable';

import Estimates from 'services/rest/estimates';

import ICON_INFO from 'assets/images/icons/ico_toast_info_violet.svg';

import './style.css';

const QuotesDetailsViewOverridesModal = (props) => {
	const filterInit = {
		orderBy: [],
	};

	const tableHeadersInit = [
		{ title: 'REGION', field: 'region_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'FILING FEE', field: 'professional_fee', type: 'number', sort: 'none', sortable: true },
		{ title: 'TRANSLATION FEE', field: 'translation_fee', type: 'number', sort: 'none', sortable: true },
		{ title: 'OFFICIAL FEE', field: 'official_fee', type: 'number', sort: 'none', sortable: true },
	];

	const [showLoader, setShowLoader] = useState(false);
	const [filter, setFilter] = useState(filterInit);
	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [data, setData] = useState([]);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [filter]);

	const fetchData = async () => {
		try {
			setShowLoader(true);
			if (!!filter.orderBy) {
				filter.order_by = filter.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(',');
			}
			let estimate = await Estimates.GetAllEstimateRules(props.estimate_id);
			let res = [];
			if (props.lastOverrideFees.length > 0) {
				res = props.lastOverrideFees;
			} else {
				res = await Estimates.getOverrideFees(props.estimate_id, filter);
			}
			let active_regions = props.pricingTransform(estimate, res);
			if (
				props.estimate_id === props.last_estimate_id &&
				Object.keys(props.priceTableData).length > 0 &&
				props.priceTableData[props.last_estimate_id] &&
				props.priceTableData[props.last_estimate_id].length > 0
			) {
				active_regions = props.priceTableData[props.last_estimate_id];
			}
			let overrides_data = [];
			if (res && res.length > 0) {
				for (let i in res) {
					let region = active_regions.find((ar) => ar.region_id === res[i].region_id);
					if (region) {
						overrides_data.push({
							...res[i],
							is_visible: region.is_visible,
						});
					}
				}
			} else {
				overrides_data = active_regions.map((r) => {
					let o = {
						estimate_id: props.estimate_id,
						region_code: r.region_code,
						region_name: r.region_name,
						region_id: r.region_id,
						is_visible: r.is_visible,
						official_fee: null,
						professional_fee: null,
						translation_fee: null,
					};
					return o;
				});
			}
			setData(overrides_data);
			setShowLoader(false);
		} catch (err) {
			setShowLoader(false);
			console.log(err);
		}
	};

	const fieldChange = (e) => {
		let args = e.target.name.split('__');

		let field = args[1];
		let region_id = args[0];
		let value = e.target.value ? parseInt(e.target.value) : null;

		setData(
			data.map((o) => {
				if (o.region_id === region_id) {
					o[field] = value;
				}
				return o;
			}),
		);
	};

	const columnSort = async (col) => {
		if (true) {
			setTableHeaders(
				tableHeaders.map((h) => {
					if (h.field === col && h.sortable) {
						h.sort = h.sort === 'up' ? 'down' : 'up';
						if (filter.orderBy.filter((r) => r.field === col).length === 0) {
							setFilter({
								...filter,
								orderBy: [{ field: col, direction: h.sort }, ...filter.orderBy],
							});
						} else {
							setFilter({
								...filter,
								orderBy: [{ field: col, direction: h.sort }, ...filter.orderBy.filter((c) => c.field !== col)],
							});
						}
					}
					return h;
				}),
			);
		}
	};

	const nameGenerator = (fee_type, region) => {
		return `${region}__${fee_type}`;
	};

	const submitOverrides = async () => {
		try {
			let pricing_data = props.priceTableData;
			let last_estimate_pricing_data = [];
			if (Object.keys(pricing_data).length > 0 && pricing_data[props.last_estimate_id] && pricing_data[props.last_estimate_id].length > 0) {
				last_estimate_pricing_data = pricing_data[props.last_estimate_id].map((pr) => {
					let override = data.find((o) => o.region_id === pr.region_id);
					let professional_fee = override.professional_fee != null ? override.professional_fee : pr.professional_fee;
					let translation_fee = override.translation_fee != null ? override.translation_fee : pr.translation_fee;
					let official_fee = override.official_fee != null ? override.official_fee : pr.official_fee;
					return {
						...pr,
						professional_fee: professional_fee,
						translation_fee: translation_fee,
						official_fee: official_fee,
						professional_fee_override: override.professional_fee != null ? true : false,
						translation_fee_override: override.translation_fee != null ? true : false,
						official_fee_override: override.official_fee != null ? true : false,
					};
				});

				props.setPriceTableData({
					...pricing_data,
					[props.last_estimate_id]: last_estimate_pricing_data,
				});
			}
			props.setLastOverrideFees(data);
			props.closeHandler();
		} catch (e) {
			console.log(e);
		}
	};

	const modalActions = [
		{ primary: false, label: 'Cancel', action: props.closeHandler, theme: 'azami-ghost' },
		{ primary: true, label: 'Save Overrides', action: submitOverrides, theme: 'azami-blue', disabled: props.last_estimate_id !== props.estimate_id },
	];

	return (
		<Modal title="Override Fees" closeHandler={props.closeHandler} footerActions={modalActions}>
			<div className="override-fees-modal">
				<div className="override-fees-modal__body">
					<LoaderOverlay showLoader={showLoader} />
					<DataTable fixedHeader={false} columns={tableHeaders} onColumnSort={columnSort}>
						{data?.map((o) => {
							return (
								<tr key={`${o.region_id}`} className={o.is_visible ? '' : 'grayed-out'}>
									<DataTable.CountryCell code={o.region_code || ''}>{o.region_name}</DataTable.CountryCell>
									<DataTable.Cell>
										<label className="override-fees-modal__table-currency-symbol">{props.currency_symbol}</label>
										<Input
											name={nameGenerator('professional_fee', o.region_id)}
											onChange={fieldChange}
											type="number"
											value={o.professional_fee}
											theme={o.is_visible ? '' : 'grayed-out'}
										/>
									</DataTable.Cell>
									<DataTable.Cell>
										<label className="override-fees-modal__table-currency-symbol">{props.currency_symbol}</label>
										<Input
											name={nameGenerator('translation_fee', o.region_id)}
											onChange={fieldChange}
											type="number"
											value={o.translation_fee}
											theme={o.is_visible ? '' : 'grayed-out'}
										/>
									</DataTable.Cell>
									<DataTable.Cell>
										<label className="override-fees-modal__table-currency-symbol">{props.currency_symbol}</label>
										<Input name={nameGenerator('official_fee', o.region_id)} onChange={fieldChange} type="number" value={o.official_fee} theme={o.is_visible ? '' : 'grayed-out'} />
									</DataTable.Cell>
								</tr>
							);
						})}
					</DataTable>
				</div>
				<div className="override-fees-modal__footer">
					<div className="override-fees-modal__footer-note">
						<div className="override-fees-modal__footer-note-asterisk">
							<div className="quote-info-image-container">
								<img src={ICON_INFO} alt="" />
							</div>
						</div>
						<div className="override-fees-modal__footer-note-text">
							{` A zero (0) value will set the fee to ${props.currency_symbol}0. To revert to using the quote's estimator rule for a specific fee, delete the value entered.`}
						</div>
					</div>
					<div className="override-fees-modal__footer-note">
						<div className="override-fees-modal__footer-note-asterisk">
							<div className="quote-info-image-container">
								<img src={ICON_INFO} alt="" />
							</div>
						</div>
						<div className="override-fees-modal__footer-note-text">{`Rows displayed in grayscale are currently hidden on the quote.`}</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default QuotesDetailsViewOverridesModal;
