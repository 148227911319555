import { APP_NUM_IGNORED_KEY_WORDS, SERVICE_KEYS, UTILITY_MODEL_PRIORITY_TYPE } from 'data/constants';

const pctAndParisErrors = {
	errorFields: {
		client_id: '',
		agree_to_terms: '',
		regions: '',
		application_number: '',
		application_technologies: '',
		application_title: '',
		number_of_words: '',
		number_of_pages: '',
		number_of_claims: '',
		number_of_independent_claims: '',
		entity_size: '',
		application_language: '',
	},
	errorFieldsExternal: {
		client_id: '',
		regions: '',
		application_number: '',
		application_technologies: '',
		application_title: '',
		number_of_words: '',
		number_of_pages: '',
		number_of_claims: '',
		number_of_independent_claims: '',
		entity_size: '',
		application_language: '',
	},
	quoteDetailsFields: ['application_title', 'number_of_words', 'number_of_pages', 'number_of_claims', 'number_of_independent_claims', 'entity_size', 'application_language'],
	exclude: {
		application_technologies: ['saas_user'],
	},
};

// Default validation fields for Recordal, Assignments, PCT Application (International Phase), Prosecution, Recordal and Unitary Patent
// same as pctAndParisErrors without technologies
const defaultService = {
	errorFields: {
		client_id: '',
		agree_to_terms: '',
		regions: '',
		application_number: '',
		application_title: '',
		number_of_words: '',
		number_of_pages: '',
		number_of_claims: '',
		number_of_independent_claims: '',
		entity_size: '',
		application_language: '',
	},
	errorFieldsExternal: {
		client_id: '',
		regions: '',
		application_number: '',
		application_title: '',
		number_of_words: '',
		number_of_pages: '',
		number_of_claims: '',
		number_of_independent_claims: '',
		entity_size: '',
		application_language: '',
	},
	quoteDetailsFields: ['application_title', 'number_of_words', 'number_of_pages', 'number_of_claims', 'number_of_independent_claims', 'entity_size', 'application_language'],
};

const designErrors = {
	errorFields: {
		client_id: '',
		agree_to_terms: '',
		regions: '',
		application_title: '',
		number_of_page_drawings: '',
		entity_size: '',
		application_language: '',
	},
	errorFieldsExternal: {
		client_id: '',
		regions: '',
		application_title: '',
		number_of_page_drawings: '',
		entity_size: '',
		application_language: '',
	},
	quoteDetailsFields: ['application_title', 'number_of_page_drawings', 'entity_size', 'application_language'],
};

const trademarkErrors = {
	errorFields: {
		client_id: '',
		agree_to_terms: '',
		regions: '',
		application_title: '',
		number_of_classes: '',
		application_language: '',
	},
	errorFieldsExternal: {
		client_id: '',
		regions: '',
		application_title: '',
		number_of_classes: '',
		application_language: '',
	},
	quoteDetailsFields: ['application_title', 'number_of_classes', 'application_language'],
};

const epValidationErrors = {
	errorFields: {
		client_id: '',
		agree_to_terms: '',
		regions: '',
		application_number: '',
		application_title: '',
		number_of_words: '',
		number_of_pages: '',
		number_of_claims: '',
		number_of_words_in_claims: '',
		application_language: '',
	},
	errorFieldsExternal: {
		client_id: '',
		regions: '',
		application_number: '',
		application_title: '',
		number_of_pages: '',
		number_of_claims: '',
		application_language: '',
	},
	quoteDetailsFields: ['application_title', 'number_of_pages', 'number_of_claims', 'number_of_words_in_claims', 'number_of_words', 'application_language'],
};

const unitaryPatentErrors = {
	errorFields: {
		client_id: '',
		agree_to_terms: '',
		regions: '',
		application_number: '',
		application_title: '',
		number_of_words_in_claims: '',
		number_of_words: '',
		application_language: '',
	},

	errorFieldsExternal: {
		client_id: '',
		regions: '',
		application_number: '',
		application_title: '',
		number_of_words: '',
		application_language: '',
	},
	quoteDetailsFields: ['application_title', 'number_of_words_in_claims', 'number_of_words', 'application_language'],
};

const translationErrors = {
	errorFields: {
		client_id: '',
		agree_to_terms: '',
		regions: '',
		number_of_words: '',
		application_language: '',
	},
	errorFieldsExternal: {
		client_id: '',
		regions: '',
		number_of_words: '',
		application_language: '',
	},
	quoteDetailsFields: ['number_of_words', 'application_language'],
};

const utilityModelErrors = {
	errorFields: {
		client_id: '',
		agree_to_terms: '',
		regions: '',
		application_number: '',
		application_technologies: '',
		application_title: '',
		number_of_words: '',
		number_of_pages: '',
		number_of_claims: '',
		number_of_independent_claims: '',
		priority_type: '',
		entity_size: '',
		application_language: '',
	},
	errorFieldsExternal: {
		client_id: '',
		regions: '',
		application_number: '',
		application_technologies: '',
		application_title: '',
		number_of_words: '',
		number_of_pages: '',
		number_of_claims: '',
		number_of_independent_claims: '',
		priority_type: '',
		entity_size: '',
		application_language: '',
	},
	quoteDetailsFields: ['application_title', 'number_of_words', 'number_of_pages', 'number_of_claims', 'number_of_independent_claims', 'entity_size', 'application_language'],
	exclude: {
		application_technologies: ['saas_user'],
	},
};

const recordalErrors = {
	errorFields: {
		recordal_type: '',
		regions: '',
		client_firm_id: '',
		client_id: '',
	},
	errorFieldsExternal: {
		recordal_type: '',
		regions: '',
		client_id: '',
	},
	quoteDetailsFields: [],
};

const otherErrors = {
	errorFields: {
		client_id: '',
		agree_to_terms: '',
		regions: '',
		application_language: '',
	},
	errorFieldsExternal: {
		client_id: '',
		regions: '',
		application_language: '',
	},
};

const selectErrorFields = (serviceId, services) => {
	let service = services.find((s) => s.id === serviceId);
	let serviceName = service?.name;
	switch (serviceName) {
		case 'Translations':
			return translationErrors;
		case 'Other':
			return otherErrors;
		case 'Utility Model':
			return utilityModelErrors;
		case 'PCT National Phase':
			return pctAndParisErrors;
		case 'Design Applications':
			return designErrors;
		case 'European Validation':
			return epValidationErrors;
		case 'Trademarks':
			return trademarkErrors;
		case 'Paris Convention':
			return pctAndParisErrors;
		case 'Recordal':
			return recordalErrors;
		case 'Unitary Patent':
			return unitaryPatentErrors;
		default:
			return defaultService;
	}
};

export const validate = (data, services, external_user, userRole) => {
	let service = services.find((s) => s.id === data.service_id);
	let isValid = true;
	let errorFields = {};
	if (!data.service_id) {
		errorFields.service_id = errorMessages.required;
		isValid = false;
		return { isValid, errorFields };
	}

	if (service && service.key === SERVICE_KEYS.PCT && data.application_number && !APP_NUM_IGNORED_KEY_WORDS.includes(data.application_number.toUpperCase())) {
		let valid = validateApplicationNumberFormat(data.application_number);
		if (!valid) {
			errorFields.application_number = errorMessages.applicationNumberFormat;
			isValid = false;
			return { isValid, errorFields };
		}
	}

	let errorList = selectErrorFields(data.service_id, services);
	let fields = external_user ? errorList.errorFieldsExternal : errorList.errorFields;
	for (let f in fields) {
		if (errorList && errorList.exclude) {
			if (Array.isArray(errorList.exclude[f]) && errorList.exclude[f].includes(userRole)) continue;
		}
		if (!data[f] || (Array.isArray(data[f]) && data[f].length === 0)) {
			errorFields[f] = errorMessages.required;
			if (errorList.quoteDetailsFields && errorList.quoteDetailsFields.includes(f)) {
				errorFields.quote_details_header_error = true;
			}
			isValid = false;
		}
	}
	return { isValid, errorFields };
};

export const requiredQuoteDetailsField = (service_id, services, field_name, external_user) => {
	let fieldsList = selectErrorFields(service_id, services);
	if (external_user) {
		return fieldsList.errorFieldsExternal.hasOwnProperty(field_name);
	}
	return fieldsList.errorFields.hasOwnProperty(field_name);
};

export const validateApplicationNumberFormat = (app_number) => {
	let application_number = app_number.toUpperCase();
	let pattern = /^(PCT\/[A-Z]{2}(\d{2}){1,2}|WO\/(\d{4}))\/[0-9]{1,6}$/;
	let result = pattern.test(application_number);
	return result;
};

export const validateDeadline = (date, service_id, services, priority_type) => {
	let service = services.find((s) => s.id === service_id);
	let deadline, d;
	switch (service.key) {
		case SERVICE_KEYS.PCT:
			deadline = calculateDeadline(date, 31);
			d = calculateDeadlineDifference(deadline);
			if (d < 0) {
				return true;
			} else {
				return false;
			}
		case SERVICE_KEYS.PARIS:
			deadline = calculateDeadline(date, 12);
			d = calculateDeadlineDifference(deadline);
			if (d < 0) {
				return true;
			} else {
				return false;
			}
		case SERVICE_KEYS.EP:
			deadline = calculateDeadline(date, 3);
			d = calculateDeadlineDifference(deadline);
			if (d < 0) {
				return true;
			} else {
				return false;
			}
		case SERVICE_KEYS.TRADEMARKS:
			deadline = calculateDeadline(date, 6);
			d = calculateDeadlineDifference(deadline);
			if (d < 0) {
				return true;
			} else {
				return false;
			}
		case SERVICE_KEYS.DESIGN:
			deadline = calculateDeadline(date, 6);
			d = calculateDeadlineDifference(deadline);
			if (d < 14) {
				return true;
			} else {
				return false;
			}
		case SERVICE_KEYS.UNITARY:
			deadline = calculateDeadline(date, 1);
			d = calculateDeadlineDifference(deadline);
			if (d < 0) {
				return true;
			} else {
				return false;
			}
		case SERVICE_KEYS.OTHER:
			deadline = calculateDeadline(date, 0);
			d = calculateDeadlineDifference(deadline);
			if (d < 0) {
				return true;
			} else {
				return false;
			}
		case SERVICE_KEYS.UTILITY:
			if (!priority_type) {
				return false;
			}
			if (priority_type === UTILITY_MODEL_PRIORITY_TYPE.PARIS_CONVENTION) {
				deadline = calculateDeadline(date, 12);
			} else {
				deadline = calculateDeadline(date, 31);
			}
			d = calculateDeadlineDifference(deadline);
			if (d < 0) {
				return true;
			} else {
				return false;
			}
		default:
			return false;
	}
};

export const validateInstructionDeadline = (date, service_id, services, priority_type) => {
	let service = services.find((s) => s.id === service_id);
	let deadline;
	if (service.key === SERVICE_KEYS.PCT || (service.key === SERVICE_KEYS.UTILITY && priority_type === UTILITY_MODEL_PRIORITY_TYPE.PCT_NPE)) {
		deadline = calculateDeadline(date, 1);
	} else {
		deadline = calculateDeadline(date, 0);
	}
	let d = calculateDeadlineDifference(deadline);
	if (service.key === SERVICE_KEYS.DESIGN) {
		if (d < 13) {
			return true;
		} else {
			return false;
		}
	} else {
		if (d < -1) {
			return true;
		} else {
			return false;
		}
	}
};

const calculateDeadlineDifference = (d) => {
	let new_date = new Date();
	let now_utc = Date.UTC(new_date.getUTCFullYear(), new_date.getUTCMonth(), new_date.getUTCDate());
	return (d - now_utc) / (1000 * 60 * 60 * 24);
};

const calculateDeadline = (d, months) => {
	let t = d.getUTCDate();
	d.setUTCDate(1);
	d.setUTCMonth(d.getUTCMonth() + months);
	d.setUTCDate(Math.min(t, getDaysInMonth(d)));
	let day_of_the_week = d.getDay();
	if (day_of_the_week == 6) {
		d.setDate(d.getDate() + 2);
	}
	if (day_of_the_week == 0) {
		d.setDate(d.getDate() + 1);
	}
	return d;
};

const isLeapYear = (d) => {
	let year = d.getUTCFullYear();
	return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

const getDaysInMonth = (d) => {
	let month = d.getUTCMonth();
	return [31, isLeapYear(d) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

const errorMessages = {
	required: 'Required',
	applicationNumberFormat: 'Format',
};

export const errorFieldsInit = {
	application_number: '',
	service_id: '',
	application_technologies: '',
	regions: '',
	application_title: '',
	number_of_words: '',
	number_of_pages: '',
	number_of_claims: '',
	number_of_independent_claims: '',
	number_of_page_drawings: '',
	number_of_classes: '',
	number_of_words_in_claims: '',
	recordal_type: '',
	agree_to_terms: '',
	client_firm_id: '',
	associate_tab: '',
	entity_size: '',
	application_language: '',
};

export const calculateChinaExtensionSwitch = (date) => {
	let current_date = new Date();
	let deadline = new Date(date.setMonth(date.getMonth() + 30));
	let difference = deadline.getTime() - current_date.getTime();
	let days = Math.ceil(difference / (1000 * 3600 * 24));
	if (days <= 24) {
		return true;
	}
	return false;
};
