import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Loader from 'com/ui/Loader';
import { OwnerContext } from 'util/ownerContext';

import './style.css';

const Button = (props) => {
	const userData = useSelector((state) => state.auth.user);
	const ownerData = useContext(OwnerContext);

	const checkRoles = (uData, roles) => {
		if (props.disabled) return props.disabled;
		if (uData.role.includes('admin')) return false;
		if (roles.includes('$user') && ownerData.userMatch) return false;
		if (roles.includes('$firm') && ownerData.firmMatch) return false;
		if (roles.length === 0) return false;
		let uRoles = uData.role.filter((r) => {
			return roles.includes(r);
		});
		return uRoles.length === 0;
	};

	let { theme, type, className, showSpinner, onClick, forRoles, activeClasName, ...rest } = props;
	let disabled = checkRoles(userData, forRoles) ? 'disabled' : '';

	return (
		<button {...rest} type={type} className={`form-button ${disabled} ${theme} ${className ? className : ''} ${showSpinner ? 'has-spinner' : ''}`} onClick={onClick} disabled={disabled}>
			{showSpinner ?
				<Loader />
			:	props.children}
		</button>
	);
};

Button.propTypes = {
	theme: PropTypes.oneOf(['grey', 'light', 'dark', 'ghost', 'lightblue', 'blurple', 'azami-ghost', 'azami-blue', 'midgreen', 'azami-light-violet', 'ipeer-blue', 'none', 'bordered', 'ghost-text']),
	type: PropTypes.string,
	className: PropTypes.string,
	showSpinner: PropTypes.bool,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	forRoles: PropTypes.array,
};

Button.defaultProps = {
	theme: 'light',
	className: '',
	showSpinner: false,
	onClick: () => {},
	// disabled: false,
	forRoles: [],
};

export default Button;
