import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Input from 'com/ui/Input';
import Select from 'com/ui/Select';
import Textarea from 'com/ui/Textarea';
import Button from 'com/ui/Button';
import DataTable from 'com/widgets/DataTable';
import LoaderOverlay from 'com/ui/LoaderOverlay';
import { RoleBox } from 'com/util/RoleBox';
import Modal from 'com/widgets/Modal';
import DocumentUploader from 'com/widgets/DocumentUploader';
import DocumentPicker from 'com/widgets/DocumentPicker';

import Storage from 'services/rest/storage';
import Documents from 'services/rest/documents';
import Cases from 'services/rest/cases';

import { DOCUMENT_OBJECT_TYPE, FILE_CATEGORY, INTERNAL_ROLES, PAGE_TITLE, ROLE_TYPES } from 'data/constants';
import { API_HOST, DOMAINS } from 'data/constants';
import { random, encodeURI, getDomain } from 'services/strings';

import useTitle from 'services/hooks/useTitle';

import ICON_WEBSITE from 'assets/images/icons/ico_website.svg';
import ICON_DELETE from 'assets/images/icons/ico_btn_delete_lightgrey.svg';
import ICON_PAPER_CLIP from 'assets/images/icons/ico_paperclip.svg';
import ICON_DOWNLOAD from 'assets/images/icons/ico-download.svg';
import ICON_UPLOAD from 'assets/images/icons/ico-upload.svg';
import ICON_INFO from 'assets/images/icons/ico_info_magenta.svg';
import ICON_EDIT from 'assets/images/icons/ico_btn_edit_lightgrey.svg';

import './style.css';

const DocumentsTable = (props) => {
	const tableHeadersInit = [
		{ title: 'region', field: 'region_name', sort: 'none', sortable: true },
		{ title: 'category', field: 'category', sort: 'none', sortable: false },
		{ title: 'file', field: 'name', sort: 'none', sortable: false },
		{ title: 'description', field: 'description', sort: 'none', sortable: false },
		{ title: '', field: '', sort: 'none', sortable: false },
		{ title: '', field: '', sort: 'none', sortable: false },
	];
	const filterInit = {
		fileName: '',
		region: '',
		category: '',
		attached_by: '',
		documentsID: props.documentsID,
		order_by: [],
	};

	// time frame in which external users can update document
	const maxVisibleHours = 2;

	const [documents, setDocuments] = useState([]);
	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [caseRegionsSelectOptions, setCaseRegionsSelectOptions] = useState([]);
	const [filterRegionsSelectOptions, setFilterRegionsSelectOptions] = useState([]);
	const [attachedByOptions, setAttachedByoptions] = useState([]);
	const [files, setFiles] = useState([]);
	const [duplicateFiles, setDuplicateFiles] = useState([]);
	const [category, setCategory] = useState('');
	// regionID is id of the case/quote_region or all if document is for all regions
	const regionIDInit = 'all';
	const [regionID, setRegionID] = useState(regionIDInit);
	const [description, setDescription] = useState('');
	const [uploadModalOpen, setUploadModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [duplicatesModalOpen, setDuplicatesModalOpen] = useState(false);
	const [pickerModalOpen, setPickerModalOpen] = useState(false);

	const [showLoader, setShowLoader] = useState(false);
	const [fileForDelete, setFileForDelete] = useState({});
	const [filterData, setFilterData] = useState(filterInit);
	const uploadBoxIndicatorsInit = { message: '', noFilesSelected: false };
	const [uploadBoxIndicators, setUploadBoxIndicators] = useState(uploadBoxIndicatorsInit);
	const [editMode, setEditMode] = useState(false);
	const [documentForUpdate, setDocumentForUpdate] = useState({});
	const [selectedDocuments, setSelectedDocuments] = useState([]);
	const { id } = useParams();
	const user = useSelector((state) => state.auth.user);

	const ALL_REGIONS = 'General (All Regions)';
	const categoryMsg = 'Please Select Category';
	// hide certain categories for quotes
	const categorySelect =
		props.objectTypeState === 'CASE' ?
			FILE_CATEGORY
		:	FILE_CATEGORY.filter((c) => !(c.value === 'INVOICE_TO_CLIENT' || c.value === 'RATES_CONFIRMATION' || c.value === 'BILL_FROM_FOREIGN_ASSOCIATE'));

	const title = props.objectTypeState === DOCUMENT_OBJECT_TYPE.CASE ? PAGE_TITLE.CASE_DETAILS : PAGE_TITLE.QUOTE_DETAILS;
	useTitle(title);
	const domain = getDomain();

	useEffect(() => {
		fetchDocuments();
	}, [filterData]);

	const handleUploadFile = async () => {
		if (files.length === 0 || files.every((f) => f.is_uploaded === true)) {
			return setUploadBoxIndicators((prevState) => {
				return { ...prevState, noFilesSelected: true };
			});
		}
		if (!category) {
			return setUploadBoxIndicators((prevState) => {
				return { ...prevState, message: categoryMsg };
			});
		}
		const payload = {
			object_type: regionID === regionIDInit ? props.objectTypeState : props.objectTypeRegion,
			object_id: regionID === regionIDInit ? id : regionID,
			category: category,
			modal_upload: true,
			case_number: props.emailData.case_number,
			case_id: props.emailData.case_id,
			firm_name: props.emailData.firm_name,
			account_executive_id: props.emailData.account_executive_id,
			path: props.emailData.path,
			description: description,
		};
		const f = files.filter((fi) => fi.is_uploaded === false);
		try {
			setShowLoader(true);
			let document = await Storage.DocumentUpload(f, payload);
			setFiles((prevState) => [...prevState.filter((f) => f.is_uploaded === true)]);
			setDuplicateFiles([]);
			setCategory('');
			setDescription('');
			setUploadBoxIndicators(uploadBoxIndicatorsInit);
			if (!!props.documentsID.length) {
				let updatedDocuments = [...filterData.documentsID, document[0].id || ''];
				setFilterData((prevState) => ({
					...prevState,
					documentsID: updatedDocuments,
				}));
				props.updateDocumentsID(updatedDocuments);
			} else {
				await fetchDocuments();
			}
			setUploadModalOpen(false);
			setShowLoader(false);
		} catch (err) {
			console.log(err);
			setShowLoader(false);
		}
	};

	const handleEditFile = async () => {
		const payload = {
			...documentForUpdate,
			object_type: regionID === regionIDInit ? props.objectTypeState : props.objectTypeRegion,
			object_id: regionID === regionIDInit ? id : regionID,
			category: category,
			description: description,
		};

		try {
			setShowLoader(true);
			await Documents.UpdateByID(documentForUpdate.id, payload);
			await fetchDocuments();
			setEditMode(false);
			setDocumentForUpdate({});
			setCategory('');
			setDescription('');
			setRegionID(regionIDInit);
			setUploadModalOpen(false);
			setShowLoader(false);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const openUploadModal = () => {
		setUploadModalOpen(true);
	};

	const closeUploadModal = () => {
		if (editMode === true) {
			setDocumentForUpdate({});
			setEditMode(false);
		}
		setFiles((prevState) => [...prevState.filter((f) => f.is_uploaded === true)]);
		setDuplicateFiles([]);
		setCategory('');
		setRegionID('');
		setDescription('');
		setUploadBoxIndicators(uploadBoxIndicatorsInit);
		setUploadModalOpen(false);
	};

	const selectCategory = (e) => {
		setCategory(e.target.value);
		setUploadBoxIndicators((prevState) => {
			return { ...prevState, message: '' };
		});
	};

	const selectRegion = (e) => {
		setRegionID(e.target.value);
	};

	const selectDescrition = (e) => {
		setDescription(e.target.value);
	};

	const openDeleteModal = (f) => {
		setFileForDelete(f);
		setDeleteModalOpen(true);
	};
	const closeDeleteModal = () => {
		setFileForDelete({});
		setDeleteModalOpen(false);
	};

	const filterChange = (e) => {
		setFilterData((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	};

	const documentDelete = async (id) => {
		try {
			setShowLoader(true);
			if (!!props.documentsID.length) {
				let updatedDocuments = filterData.documentsID.filter((d) => d !== id);
				setFilterData((prevState) => ({
					...prevState,
					documentsID: updatedDocuments,
				}));
				props.updateDocumentsID(updatedDocuments);
			} else {
				await Storage.DocumentDelete(id);
				await fetchDocuments();
			}
			setFileForDelete({});
			setDeleteModalOpen(false);
			setShowLoader(false);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filterData.order_by.filter((r) => r.field === col).length === 0) {
						setFilterData({
							...filterData,
							order_by: [{ field: col, direction: h.sort }, ...filterData.order_by],
						});
					} else {
						setFilterData({
							...filterData,
							order_by: [{ field: col, direction: h.sort }, ...filterData.order_by.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
	};

	const fetchDocuments = async () => {
		setShowLoader(true);
		try {
			let filter = { ...filterData };
			if (filterData.order_by) {
				filter = {
					...filterData,
					order_by: filterData.order_by.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(','),
				};
			}
			let data = await Documents.getByCase(id, filter);
			setFiles((prevState) => {
				return [
					...data.map((d) => {
						d.is_uploaded = true;
						return d;
					}),
				];
			});
			setDocuments((prevState) => {
				let docs = [];
				data.forEach((d) => {
					const category = FILE_CATEGORY.find((e) => e.value === d.category);
					d.category_label = category.label;
					let exists = docs.find((ed) => ed.object_id === d.object_id);
					if (!exists) {
						let dataByObjectId = data.filter((o) => o.object_id === d.object_id);
						let out = {
							object_id: d.object_id,
							region_name: d.region_name ? d.region_name : ALL_REGIONS,
							region_code: d.region_code,
							documents: dataByObjectId,
						};
						docs.push(out);
					}
				});
				// sort by region name (with all regions at the top) if order filter is not set
				if (!filterData.order_by.find((e) => e.field == 'region_name')) {
					docs = docs.sort((a, b) => {
						const currentName = a.region_name.toLowerCase();
						const nextName = b.region_name.toLowerCase();
						if (currentName === ALL_REGIONS.toLowerCase()) {
							return -1;
						}
						if (currentName < nextName) {
							return -1;
						}
						if (currentName > nextName) {
							return 1;
						}

						return 0;
					});
				}

				return docs;
			});

			let caseData = await Cases.GetOne(id, filter);
			// sort regions by name
			if (caseData.regions) {
				caseData.regions = caseData.regions.sort((a, b) => {
					const currentName = a.region_name.toLowerCase();
					const nextName = b.region_name.toLowerCase();
					if (currentName < nextName) {
						return -1;
					}
					if (currentName > nextName) {
						return 1;
					}

					return 0;
				});
			}

			setCaseRegionsSelectOptions([
				{ label: ALL_REGIONS, value: regionIDInit },
				...caseData.regions.map((r) => {
					return { label: r.region_name, value: r.id };
				}),
			]);
			setFilterRegionsSelectOptions([
				{ label: ALL_REGIONS, value: '' },
				...caseData.regions.map((r) => {
					return { label: r.region_name, value: r.region_id };
				}),
			]);

			const usersData = await Documents.getUploadersByCaseId(id, filterData);
			setAttachedByoptions([
				{ label: 'Select User', value: '' },
				...usersData.map((u) => {
					return { label: `${u.first_name} ${u.last_name}`, value: u.user_id };
				}),
			]);
			setShowLoader(false);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const closeDuplicatesModal = () => {
		setDuplicatesModalOpen(false);
		setDuplicateFiles([]);
	};

	const handleEdit = (e) => {
		let d = files.find((f) => f.id === e.target.dataset.did);
		setEditMode(true);
		setDocumentForUpdate(d);
		setRegionID(d.object_id);
		setCategory(d.category);
		setDescription(d.description);
		setUploadModalOpen(true);
	};

	const openPickerModal = () => {
		setPickerModalOpen(true);
	};

	const closePickerModal = () => {
		setPickerModalOpen(false);
	};

	const handleSelectDocuments = () => {
		if (!props.documentsID.length) return;

		let updatedDocuments = [...filterData.documentsID, ...selectedDocuments];
		setFilterData((prevState) => ({
			...prevState,
			documentsID: updatedDocuments,
		}));
		props.updateDocumentsID(updatedDocuments);
		setPickerModalOpen(false);
	};

	const uploadModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeUploadModal, theme: 'azami-ghost' },
		{ primary: true, label: !editMode ? 'Upload Files' : 'Save Changes', action: !editMode ? handleUploadFile : handleEditFile, theme: 'azami-blue' },
	];

	const deleteModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeDeleteModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Confirm', action: () => documentDelete(fileForDelete.id), theme: 'azami-blue' },
	];

	const duplicatesModalFooterActions = [{ primary: false, label: 'Close', action: closeDuplicatesModal, theme: 'azami-ghost' }];

	const pickerModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closePickerModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Apply', action: handleSelectDocuments, theme: 'azami-blue' },
	];

	return (
		<div className="documents-header">
			<LoaderOverlay showLoader={showLoader} />
			{DOMAINS.OPV2.includes(btoa(domain)) ?
				props.allowFilters && (
					<div className="documents-header__filters">
						<Input label="faster search by file name" name="fileName" value={filterData.fileName} onChange={filterChange} />
						<Select label="filter regions" name="region" theme="light" options={filterRegionsSelectOptions} value={filterData.region} onChange={filterChange} />
						<Select label="filter category" name="category" theme="light" options={categorySelect} value={filterData.category} onChange={filterChange} />
						<Select label="filter attached by" name="attached_by" theme="light" options={attachedByOptions} value={filterData.attached_by} onChange={filterChange} />
					</div>
				)
			:	null}
			<div className="documents-header__actions">
				<span className="documents-header__actions__title">{props.title}</span>
				<div className="documents-header__actions__buttons">
					{props.allowPickExistDocument && (
						<Button onClick={openPickerModal} className="documents-header__actions__select-exist-file-btn" theme="azami-blue">
							Select from Case Documents Files
						</Button>
					)}
					<div className="documents-header__actions__buttons-upload">
						<Button onClick={openUploadModal} className="documents-header__actions__upload-btn" theme="azami-blue">
							<img src={ICON_UPLOAD} alt="" />
							Upload Files
						</Button>
						<span className="documents-header__actions__buttons-upload-text">
							Please upload your Excel, Word, PDF or Image File. Please limit file uploads to 50MB. For larger files, please email your AE.
						</span>
					</div>
				</div>
			</div>

			<div className="documents-main">
				<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort} customClassName="documents-main__table">
					{documents.map((d) => {
						if (d.documents.length === 0) {
							return null;
						}
						return (
							<DataTable.Row key={random(8)}>
								{d.region_code ?
									<DataTable.CountryCell code={d.region_code}>{d.region_name}</DataTable.CountryCell>
								:	<DataTable.CellWithBadge badge={ICON_WEBSITE}>{d.region_name}</DataTable.CellWithBadge>}
								<DataTable.Cell>
									<div className="documents-main__table-file_category">
										{d.documents.map((d) => {
											return <span key={d.id}>{d.category_label}</span>;
										})}
									</div>
								</DataTable.Cell>
								<DataTable.Cell>
									<div>
										{d.documents.map((d) => {
											return (
												<div className="documents-main__table-file_name" key={d.id}>
													<img src={ICON_PAPER_CLIP} alt="" />
													<span>{d.name}</span>
												</div>
											);
										})}
									</div>
								</DataTable.Cell>
								<DataTable.Cell>
									<div>
										{d.documents.map((d) => {
											return (
												<div className="documents-main__table-file_description" key={d.id}>
													<span>{d.description}</span>
												</div>
											);
										})}
									</div>
								</DataTable.Cell>
								<DataTable.Cell>
									<div>
										{d.documents.map((d) => {
											return (
												<div key={d.id} className="documents-main__table__info">
													<img src={ICON_INFO} />
													<ToolTip>
														<span>File Size: {(d.size / (1024 * 1024)).toFixed(2)} MB</span>
														<span>Attached By: {d.attached_by}</span>
													</ToolTip>
												</div>
											);
										})}
									</div>
								</DataTable.Cell>
								<DataTable.Cell>
									<div className="documents-main__table-file_action">
										{d.documents.map((d) => {
											return (
												<div key={d.id} className="documents-main__table-file_action__actions-wrapper">
													<a href={`${API_HOST}/storage/document/${encodeURI(d.name)}?id=${d.id}`}>
														<img src={ICON_DOWNLOAD} alt="" />
													</a>
													{props.allowEdit && !(user.roleType === ROLE_TYPES.EXTERNAL && d.timestamp_diff >= maxVisibleHours) ?
														<button onClick={handleEdit} data-did={d.id} className="documents-main__table-file_action__edit-btn">
															<img src={ICON_EDIT} alt="" />
														</button>
													:	null}
													<RoleBox roles={[...INTERNAL_ROLES, 'user', 'member', 'member_assistant']}>
														<button onClick={() => openDeleteModal(d)}>
															<img src={ICON_DELETE} alt="" />
														</button>
													</RoleBox>
												</div>
											);
										})}
									</div>
								</DataTable.Cell>
							</DataTable.Row>
						);
					})}
				</DataTable>
			</div>
			{uploadModalOpen ?
				<Modal title={!editMode ? 'Upload Files' : 'Edit File'} closeHandler={closeUploadModal} footerActions={uploadModalFooterActions}>
					<Select label="Region" options={caseRegionsSelectOptions} value={regionID} onChange={selectRegion} />
					<Select label="Category" options={categorySelect} value={category} onChange={selectCategory} />
					<Textarea label="Description" value={description} onChange={selectDescrition} />
					<LoaderOverlay showLoader={showLoader} />
					<DocumentUploader
						title={!editMode ? 'upload' : 'document'}
						files={files}
						setFiles={setFiles}
						setDuplicateFiles={setDuplicateFiles}
						setDuplicatesModalOpen={setDuplicatesModalOpen}
						uploadBoxIndicators={uploadBoxIndicators}
						setUploadBoxIndicators={setUploadBoxIndicators}
						documentForUpdate={documentForUpdate}
						editMode={editMode}
						checkFileType={true}
					/>
				</Modal>
			:	null}
			{deleteModalOpen ?
				<Modal title="Delete File" closeHandler={closeDeleteModal} footerActions={deleteModalFooterActions}>
					<LoaderOverlay showLoader={showLoader} />
					<p className="confirmation-modal__body__confirm-delete">
						Delete <span>{`${fileForDelete.name} ?`}</span>
					</p>
				</Modal>
			:	null}
			{duplicatesModalOpen ?
				<Modal title="Duplicate file names are not allowed" closeHandler={closeDuplicatesModal} footerActions={duplicatesModalFooterActions}>
					<div className="duplicates__modal__content">
						{duplicateFiles.map((f, i) => {
							return <span key={i}>{f.name}</span>;
						})}
					</div>
				</Modal>
			:	null}
			{pickerModalOpen ?
				<Modal title="Case Documents Files" closeHandler={closePickerModal} footerActions={pickerModalFooterActions}>
					<DocumentPicker object_type_region={props.objectTypeRegion} object_type_state={props.objectTypeState} setSelectedDocuments={setSelectedDocuments} />
				</Modal>
			:	null}
		</div>
	);
};

export const ToolTip = (props) => {
	return <div className="info__tooltip">{props.children}</div>;
};

DocumentsTable.propTypes = {
	objectTypeRegion: PropTypes.string,
	objectTypeState: PropTypes.string,
	title: PropTypes.string,
	documentsID: PropTypes.array,
	updateDocumentsID: PropTypes.func,
	allowPickExistDocument: PropTypes.bool,
	allowFilters: PropTypes.bool,
	allowEdit: PropTypes.bool,
};

DocumentsTable.defaultProps = {
	objectTypeRegion: '',
	objectTypeState: '',
	title: 'Documents',
	documentsID: [],
	updateDocumentsID: () => {},
	allowPickExistDocument: false,
	allowFilters: true,
	allowEdit: true,
};

export default DocumentsTable;
