//Template Imports
import AuthBox from 'com/templates/AuthBox';
//UI Imports
import BackButton from 'com/ui/BackButton';
import Button from 'com/ui/Button';
//ICON imports
import ICON_BACK_LOGIN from 'assets/images/icons/login_back_light_purple_blue.svg';
import ICON_SUCCESS from 'assets/images/icons/ico_checked_success.svg';
//Hooks Imports
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
//Service Imports
import Auth from 'services/rest/auth';

//Style Imports
import './style.css';

const RegisterThankyouIPeer = () => {
	const [showEmailResentMessage, setShowEmailResentMessage] = useState(false);
	const location = useLocation();
	let email = location.state.email;
	const handleOnClick = async () => {
		try {
			await Auth.SendRegistrationEmail(email);
			setShowEmailResentMessage(true);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className="thankyou-container">
			<AuthBox
				title="Thank you for your registration"
				titleColor="dark-grey"
				icon="create_account"
				footer={
					<>
						{showEmailResentMessage ?
							<div className="thankyou-container__footer">
								<img src={ICON_SUCCESS} className="thankyou-container__footer__icon" />
								<div>Verification email resent. Please check you inbox or spam folder.</div>
							</div>
						:	null}
						<BackButton icon={ICON_BACK_LOGIN} to="/">
							Back to login
						</BackButton>
					</>
				}
			>
				An email with a verification link is on it's way. Please verify your account in order to proceed with using The Azami Global portal.
				<div className="resend-email-container">
					Didn't get the email?
					<Button onClick={handleOnClick} theme="ghost-text">
						Click here to resend
					</Button>
				</div>
			</AuthBox>
		</div>
	);
};

export default RegisterThankyouIPeer;
