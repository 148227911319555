import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Input from 'com/ui/Input';
import Select from 'com/ui/Select';
import Button from 'com/ui/Button';
import DataTable, { Paging } from 'com/widgets/DataTable';
import LetterBadge from 'com/ui/LetterBadge';
import BooleanBadge from 'com/ui/BooleanBadge';
import Modal from 'com/widgets/Modal';
import EstimatorRulesPreview from 'com/widgets/EstimatorRulesPreview';
import AppDate from 'com/util/AppDate';

import Settings from 'services/rest/settings';
import Estimates from 'services/rest/estimates';
import useDebounce from 'services/hooks/useDebounce';
import useFilterStorage from 'services/hooks/useFilterStorage';

import { DEBOUNCE_DELAY_MS } from 'data/constants';

import './style.css';

const SystemSettingsEstimatorRulesMain = (props) => {
	const history = useHistory();

	const tableHeadersInit = [
		{ title: 'Rule Name', field: 'name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Services', field: 'flat_services', type: 'string', sort: 'none', sortable: false },
		{ title: 'Regions', field: 'flat_regions', type: 'string', sort: 'none', sortable: false },
		{ title: 'Fee', field: 'fee_type', type: 'string', sort: 'none', sortable: true },
		{ title: 'Pricing Level', field: 'use_pricing_level', type: 'boolean', sort: 'none', sortable: true },
		{ title: 'Last Edited', field: '_modified', type: 'date', sort: 'none', sortable: true },
		{ title: '', field: 'associate_firm_name', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: 'delete', type: 'string', sort: 'none', sortable: false },
	];

	const feeTypes = [
		{ label: 'All', value: '' },
		{ label: 'Filing', value: 'PROFESSIONAL' },
		{ label: 'Official', value: 'OFFICIAL' },
		{ label: 'Translation', value: 'TRANSLATION' },
	];

	const badgeColors = {
		PROFESSIONAL: '#026EB3',
		OFFICIAL: '#10B5B5',
		TRANSLATION: '#BD2FEE',
	};

	const filterInit = {
		name: '',
		region: '',
		service: '',
		fee_type: '',
		object_level: 'SYSTEM',
		orderBy: [],
		deleted: '0',
	};

	const showDeletedValuesInit = [
		{ label: 'Active', value: '0' },
		{ label: 'Archived', value: '1' },
		{ label: 'All', value: '' },
	];

	const [services, setServices] = useState([]);
	const [regions, setRegions] = useState([]);
	const [rules, setRules] = useState([]);
	const [filters, setFilters] = useState(filterInit);
	const [ruleToDelete, setRuleToDelete] = useState({ id: '', parentId: '' });
	const [ruleToUnarchive, setRuleToUnarchive] = useState({ id: '', parentId: '' });
	const [ruleToPreview, setRuleToPreview] = useState('');
	const [showRulePreview, setShowRulePreview] = useState(false);
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
	const [showUnarchiveConfirmationModal, setShowUnarchiveConfirmationModal] = useState(false);
	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [paging, setPaging] = useState({ total_rows: 0, page: 1, limit: Paging.PAGE_SIZE[0].value });
	const [pageChange, setPageChange] = useState(1);
	const [showArchived, setShowArchived] = useState('0');

	const { getFilterValue, setFilterValue } = useFilterStorage();
	const debouncedFilters = useDebounce(filters, DEBOUNCE_DELAY_MS);

	useEffect(() => {
		(async () => {
			try {
				let regionData = await Settings.GetRegions();
				setRegions([
					{ label: 'All', value: '' },
					...regionData.map((c) => {
						return { ...c, label: c.name, value: c.id };
					}),
				]);

				let servicesData = await Settings.GetServices();
				setServices([
					{ label: 'All', value: '' },
					...servicesData.map((c) => {
						return { ...c, label: c.name, value: c.id };
					}),
				]);

				let f = getFilterValue();
				if (f) setFilters(f);
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	const getRules = async () => {
		try {
			let filter = {
				name: filters.name,
				region: filters.region,
				service: filters.service,
				fee_type: filters.fee_type,
				object_level: filters.object_level,
				order_by: '',
				page: paging.page,
				limit: paging.limit,
				deleted: filters.deleted,
			};
			if (!!filters.orderBy) {
				filter.order_by = filters.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(',');
			}
			let rules = await Estimates.GetAllRules(filter);
			setRules(rules.data);
			setPaging({ ...paging, total_rows: rules.total_rows });
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getRules();
	}, [pageChange, debouncedFilters]);

	const createNewLink = () => {
		history.push('/system-settings/estimator-rules/add');
	};

	const idsToNames = (ids, array) => {
		if (ids && ids.length > 0) {
			return ids.map((i) => {
				return array.find((a) => a.id == i)?.name;
			});
		}
		return [];
	};

	const getCountryCode = (id) => {
		let region = regions.find((r) => r.id == id);
		return region?.code;
	};

	const getCountryName = (id) => {
		let region = regions.find((r) => r.id == id);
		return region?.name || '';
	};

	const onFilterChange = (e) => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value,
		});

		setFilterValue({
			...filters,
			[e.target.name]: e.target.value,
		});
	};

	const editRule = (e) => {
		e.stopPropagation();
		history.push(`/system-settings/estimator-rules/${e.target.dataset.id}`);
	};

	const deleteRule = async (e) => {
		try {
			await Estimates.DeleteRuleByParentId(ruleToDelete.parentId, 'SYSTEM');
			if (filters.deleted !== '') {
				setRules(rules.filter((r) => r.id !== ruleToDelete.id));
			} else {
				setRules(
					rules.map((r) => {
						if (r.id === ruleToDelete.id) {
							r._deleted = true;
						}
						return r;
					}),
				);
			}
			setShowDeleteConfirmationModal(false);
			setShowRulePreview(false);
		} catch (err) {
			console.log(err);
		}
	};

	const unarchiveRule = async (e) => {
		try {
			await Estimates.UnarchiveRuleByParentId(ruleToUnarchive.parentId);
			if (filters.deleted !== '') {
				setRules(rules.filter((r) => r.id !== ruleToUnarchive.id));
			} else {
				setRules(
					rules.map((r) => {
						if (r.id === ruleToUnarchive.id) {
							r._deleted = false;
						}
						return r;
					}),
				);
			}
			setShowUnarchiveConfirmationModal(false);
			setShowRulePreview(false);
		} catch (err) {
			console.log(err);
		}
	};

	const openDeleteModal = (e, parentId) => {
		e.stopPropagation();
		if (e.target.dataset.id === undefined) {
			setRules(rules.filter((p, i) => i != e.target.dataset.index));
			return;
		}
		setShowDeleteConfirmationModal(true);
		// setRuleToDelete(e.target.dataset.id);
		setRuleToDelete({ id: e.target.dataset.id, parentId: parentId });
	};

	const openUnarchiveModal = (e, parentId) => {
		e.stopPropagation();
		if (e.target.dataset.id === undefined) {
			setRules(rules.filter((p, i) => i != e.target.dataset.index));
			return;
		}
		setShowUnarchiveConfirmationModal(true);
		setRuleToUnarchive({ id: e.target.dataset.id, parentId: parentId });
	};

	const closeDeleteModal = () => {
		setShowDeleteConfirmationModal(false);
	};

	const closeUnarchiveModal = () => {
		setShowUnarchiveConfirmationModal(false);
	};

	const deleteModalActions = [
		{ primary: false, label: 'Close', action: closeDeleteModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Archive', action: deleteRule, theme: 'azami-blue' },
	];

	const unarchiveModalActions = [
		{ primary: false, label: 'Close', action: closeUnarchiveModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Unarchive', action: unarchiveRule, theme: 'azami-blue' },
	];

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filters.orderBy.filter((r) => r.field === col).length === 0) {
						setFilters({
							...filters,
							orderBy: [{ field: col, direction: h.sort }, ...filters.orderBy],
						});
					} else {
						setFilters({
							...filters,
							orderBy: [{ field: col, direction: h.sort }, ...filters.orderBy.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
		setPageChange(pageChange + 1);
	};

	const openRulePreview = async (e) => {
		let rule_id = e.currentTarget.dataset.id;
		let rule = await Estimates.GetOneRulesByID(rule_id);
		setRuleToPreview(rule);
		setShowRulePreview(true);
	};

	const closeRulePreview = () => {
		setRuleToPreview('');
		setShowRulePreview(false);
	};

	const rulePreviewActions = [
		{ theme: 'azami-ghost', label: 'Archive Rule', action: openDeleteModal, condition: { field: '_deleted', value: 0 } },
		{ theme: 'azami-ghost', label: 'Unarchive Rule', action: openUnarchiveModal, condition: { field: '_deleted', value: 1 } },
		{ theme: 'azami-blue', label: 'Edit Rule', action: editRule, condition: null },
	];

	const pagingChange = (p, l) => {
		setPaging({ ...paging, page: p, limit: l });
		setPageChange(pageChange + 1);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const toggleArchived = (e) => {
		setFilters({ ...filters, deleted: e.target.dataset.value });
		setShowArchived(e.target.dataset.value);
		setPageChange(pageChange + 1);
	};

	return (
		<div className="main-screen-section white full-height no-padding system-details-content settings-estimator-rules__list-view">
			<div className="settings-estimator-rules__list-view__header">
				<Input label="Search by name" name="name" value={filters.name} onChange={onFilterChange} />
				<Select label="Region" options={regions} name="region" value={filters.region} onChange={onFilterChange} />
				<Select label="Service" options={services} name="service" value={filters.service} onChange={onFilterChange} />
				<Select label="Fee Type" options={feeTypes} name="fee_type" value={filters.fee_type} onChange={onFilterChange} />
				<Button className="settings-estimator-rules__list-view__header__add-btn" theme="blurple" onClick={createNewLink}>
					Add Rule
				</Button>
			</div>
			<div className="settings-estimator-rules__list-view__body">
				<EstimatorRulesPreview show={showRulePreview} rule={ruleToPreview} footerActions={rulePreviewActions} closeHandlrer={closeRulePreview} />
				<div className="system-settings-pricing-levels__header">
					<span className="system-settings-pricing-levels__header__title">Estimator Rules</span>
					<ul className="system-settings-pricing-levels__section-header__directions">
						{showDeletedValuesInit.map((sd, i) => {
							return (
								<li key={i} onClick={toggleArchived} data-value={sd.value} className={`dir-filter ${showArchived === sd.value && 'active'}`}>
									{sd.label}
								</li>
							);
						})}
					</ul>
				</div>
				<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort} customClassName="settings-estimator-rules__list-view__body__table">
					{rules.map((r, i) => {
						return (
							<tr key={r.id} onClick={openRulePreview} data-id={r.id}>
								<DataTable.Cell>{r.name}</DataTable.Cell>
								<DataTable.Cell>{idsToNames(r.flat_services, services).join(', ')}</DataTable.Cell>
								<DataTable.RuleRegionCell
									countryCode={getCountryCode(r.flat_regions[0])}
									cellTitle={idsToNames(r.flat_regions, regions).join(', ')}
									countryCount={r.flat_regions.length - 1}
								>
									{getCountryName(r.flat_regions[0])}
								</DataTable.RuleRegionCell>
								<DataTable.Cell>
									<LetterBadge color={badgeColors[r.fee_type]} theme="inverse">
										{r.fee_type}
									</LetterBadge>
								</DataTable.Cell>
								<DataTable.Cell>
									<BooleanBadge value={!!r.use_pricing_level} />
								</DataTable.Cell>
								<DataTable.EditedByCell user={`${r.created_by_name ? r.created_by_name : ''}`}>
									<AppDate>{new Date(r._modified)}</AppDate>
								</DataTable.EditedByCell>
								<DataTable.Cell>
									<button className="settings-estimator-rules__edit-btn" data-id={r.id} onClick={(e) => editRule(e, r.parent_id)}></button>
								</DataTable.Cell>
								<DataTable.Cell>
									{r._deleted ?
										<button className="settings-estimator-rules__unarchive-btn" data-id={r.id} onClick={(e) => openUnarchiveModal(e, r.parent_id)} title="Unarchive Rule"></button>
									:	<button className="settings-estimator-rules__delete-btn" data-id={r.id} onClick={(e) => openDeleteModal(e, r.parent_id)} title="Archive Rule"></button>}
								</DataTable.Cell>
							</tr>
						);
					})}
				</DataTable>
				<Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange} />
			</div>
			{showDeleteConfirmationModal && (
				<Modal title="Archive Estimator Rule" closeHandler={closeDeleteModal} footerActions={deleteModalActions}>
					<div>Are you sure you want to archive this rule? It will not apply to future revisions of quotes that already call it, nor be used on future quotes.</div>
				</Modal>
			)}
			{showUnarchiveConfirmationModal && (
				<Modal title="Unarchive Estimator Rule" closeHandler={closeUnarchiveModal} footerActions={unarchiveModalActions}>
					<div>Are you sure you want to restore this Estimator Rule?</div>
				</Modal>
			)}
		</div>
	);
};
export default SystemSettingsEstimatorRulesMain;
