import { useState, useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setQuoteData, clearQuoteData, setQuoteDataFromObject } from 'redux/ducks/quote';
import { clearWipoData } from 'redux/ducks/wipo';
import { setIsUpdated, redirectInit } from 'redux/ducks/redirect';

import Settings from 'services/rest/settings';
import EventLoggerEvents from 'services/rest/event_logger';
import Quotes from 'services/rest/quotes';
import Estimates from 'services/rest/estimates';
import Base from 'services/rest/base';
import Firms from 'services/rest/firms';
import Users from 'services/rest/users';
import Documents from 'services/rest/documents';
import QuotesRegions from 'services/rest/quotes-regions';
import Storage from 'services/rest/storage';

import AppTemplate from 'com/templates/ApplicationTemplate';
import Services from 'com/widgets/QuoteFields/Services';
import AdditionalInformation from 'com/widgets/QuoteFields/AdditionalInformation';
import Identifiers from 'com/widgets/QuoteFields/Identifiers';
import QuoteRegionPicker from 'com/widgets/QuoteFields/QuoteRegionPicker';
import Details from 'com/widgets/QuoteFields/Details';
import AssociateRecommender from 'com/widgets/QuoteFields/AssociateRecommender';
import QuoteActionsHeader from 'com/widgets/QuoteActions/QuoteActionsHeader';
import QuoteActionsFooter from 'com/widgets/QuoteActions/QuoteActionsFooter';
import QuoteWizardNavigation from 'com/widgets/QuoteWizardNavigation/QuoteWizardNavigation';
import InstructionDeadlineModal from 'com/widgets/InstructionDeadlineModal';
import QuoteDuplicateApplicationNumberModal from 'com/widgets/QuoteModals/QuoteDuplicateApplicationNumberModal';
import NewQuoteInstructModal from 'com/widgets/InstructModal/NewQuoteInstructModal';
import LoaderOverlay from 'com/ui/LoaderOverlay';
import Modal from 'com/widgets/Modal';

import { validate, errorFieldsInit, validateDeadline } from 'services/validators/quotes';
import { SERVICE_KEYS, ROLE_TYPES, QUOTE_EVENTS, DOCUMENT_OBJECT_TYPE, APP_NUM_IGNORED_KEY_WORDS, PAGE_TITLE, QUOTES_TABS, DOMAINS } from 'data/constants';
import { formatEmailsStringToJSON, getDomain } from 'services/strings';
import { toENCADateString } from 'services/dates';

import useNotification from 'services/hooks/use_notification';
import useTitle from 'services/hooks/useTitle';
import { getError } from 'util/errors';

import './style.css';

const NewQuote = () => {
	const locationPartials = useLocation();
	const currentLocation = locationPartials.pathname.split('/')[2];
	const location = {
		ADD: 'add',
		INSTRUCT: 'instruct-without-pre-existing',
	};
	const draftQuote = locationPartials.pathname.split('/')[4] === 'draft' ? true : false;
	const filterData = {
		firm_id: '',
		order_by: [{ field: 'first_name', direction: 'up' }],
	};
	const duplicateQuoteInit = {
		application_number: '',
		case_number: '',
	};
	const { id } = useParams();
	const user = useSelector((state) => state.auth.user);
	const quote = useSelector((state) => state.quote);
	const redirect = useSelector((state) => state.redirect);
	const history = useHistory();
	const sendNotification = useNotification();
	const showAssociateSelection = user.role.includes('saas_user');
	const domain = getDomain();
	const showAdditionalInfoSelection = user.role.includes('saas_user') ? false : true;

	const [services, setServices] = useState([]);
	const [technologies, setTechnologies] = useState([]);
	const [regions, setRegions] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [firms, setFirms] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	const [clients, setClients] = useState([]);
	const [showAllSections, setShowAllSections] = useState(false);
	const [activeIndex, setActiveIndex] = useState(1);
	const [footerBtnLabel, setFooterBtnLabel] = useState('Next');
	const [errors, setErrors] = useState(errorFieldsInit);
	const [savedDraftId, setSavedDraftId] = useState('');
	const [documents, setDocuments] = useState([]);
	const [instructionDeadlineModal, setInstructionDeadlineModal] = useState(false);
	const [duplicateQuote, setDuplicateQuote] = useState(duplicateQuoteInit);
	const [duplicatesModalOpen, setDuplicatesModalOpen] = useState(false);
	const [cancelModal, setCancelModal] = useState(false);
	const [requestSpinner, setRequestSpinner] = useState(false);
	const [draftSpinner, setDraftSpinner] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [instructModal, setInstructModal] = useState(false);
	const [selectedRegionsForInstruct, setSelectedRegionsForInstruct] = useState([]);
	const agree = useSelector((state) => state.quote.agree_to_terms);

	const dispatch = useDispatch();

	useTitle(PAGE_TITLE.REQUEST_QUOTE);

	useEffect(() => {
		(async () => {
			setShowLoader(true);
			dispatch(clearQuoteData());
			dispatch(clearWipoData());
			let event = currentLocation === location.ADD ? `Opened Request Quote Wizard` : `Opened Instruct Without Quote Wizard`;
			EventLoggerEvents.EventLogger({ event: event, data: { user: user } });
			let promises = [Settings.GetServices(), Settings.GetTechnologies(), Settings.GetRegions(), Settings.GetLanguages(), Settings.GetCurrencies(), Firms.GetOneByIDSlim(user.fid)];
			if (user.roleType === ROLE_TYPES.INTERNAL) promises.push(Firms.GetAllRaw());
			// firmsData can be undefined since it's conditionaly fetched only for internal users
			const [servicesData, technologiesData, regionsData, languagesData, currencyData, userFirm, firmsData] = await Promise.all(promises);
			setServices(servicesData);
			setTechnologies(technologiesData);
			setRegions(regionsData);
			setLanguages([
				{ label: 'Select language', value: '' },
				...languagesData.map((l) => {
					return { label: l.name, value: l.id, code: l.code };
				}),
			]);
			setCurrencies(currencyData);
			if (firmsData) {
				setFirms([
					{ label: '', value: '' },
					...firmsData.map((f) => {
						return { label: f.name, value: f.id, notes: f.notes, currency: f.currency_id };
					}),
				]);
			}

			if (!id) {
				if (user.roleType === ROLE_TYPES.EXTERNAL) {
					dispatch(setQuoteData({ name: 'client_firm_id', value: user.fid }));
					let filter = {
						order_by: filterData.order_by.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(','),
						firm_id: user.fid,
						ignore_inactive_users: true,
					};
					if (userFirm && userFirm.notes) {
						dispatch(setQuoteData({ name: 'firm_notes', value: userFirm.notes }));
					}
					let clientsData = await Users.GetAllRaw(filter);
					setClients([
						{ label: '', value: '' },
						...clientsData.map((c) => {
							return { label: `${c.first_name} ${c.last_name}`, value: c.id };
						}),
					]);
				}

				if (user.roleType === ROLE_TYPES.INTERNAL) {
					dispatch(setQuoteData({ name: 'agree_to_terms', value: true }));
				}

				if (servicesData) {
					let pctService = servicesData.find((s) => s.key === SERVICE_KEYS.PCT);
					dispatch(setQuoteData({ name: 'service_id', value: pctService.id }));
				}

				if (languagesData) {
					dispatch(setQuoteData({ name: 'application_language', value: '' }));
				}

				let internalQuoteNotes = await generateClientSpecialInstructionNote();
				if (internalQuoteNotes) {
					dispatch(setQuoteData({ name: 'internal_quote_notes', value: internalQuoteNotes }));
				}
			} else {
				let quoteData = await fetchQuoteData(id);
				dispatch(setQuoteDataFromObject(quoteData));
				if (quoteData && quoteData.client_firm_id) {
					let filter = {
						order_by: filterData.order_by.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(','),
						firm_id: quoteData.client_firm_id,
						ignore_inactive_users: true,
					};
					let clientsData = await Users.GetAllRaw(filter);
					setClients([
						{ label: '', value: '' },
						...clientsData.map((c) => {
							return { label: `${c.first_name} ${c.last_name}`, value: c.id };
						}),
					]);
				}
				// generate array of objects for additional_region_info which is generated when selecteding regions.
				// user may not change the regions when finishing draft quote so no additional_region_info would be generated.
				if (quoteData && quoteData.regions) {
					let regionData = quoteData.regions.map((r) => {
						let region = regionsData.find((reg) => reg.id === r);
						return {
							region_id: region.id,
							associate_id: '',
							firm_id: '',
							region_name: region.name,
							region_code: region.code,
							rate_official: 0,
							rate_professional: 0,
							rate_translation: 0,
							total: 0,
							comment_for_agent: '',
							is_visible: 1,
						};
					});
					dispatch(setQuoteData({ name: 'additional_region_info', value: regionData }));
				}
			}
			setShowLoader(false);
		})();
	}, []);

	/**
	 * For the iPeer users, the Additional Info tab is removed, and now the Associates tab is basically the fifth tab.
	 * However, the activeIndex is not changed for either tab, whuch is why we need this check, so that we know on which step exactly we are.
	 */
	useEffect(() => {
		const tabIndex = activeIndex === 6 ? '5' : activeIndex;
		EventLoggerEvents.EventLogger({ event: `Load Quote Wizard step ${tabIndex} : ${QUOTES_TABS[activeIndex]}`, data: { user: user } });
	}, [activeIndex]);

	const fetchQuoteData = async (id) => {
		let quoteData = await Quotes.GetOne(id);
		await getDocuments(id);
		if (quoteData) {
			if (quoteData.earliest_priority_date) {
				quoteData.earliest_priority_date = toENCADateString(quoteData.earliest_priority_date);
			}
			if (quoteData.intl_filing_day) {
				quoteData.intl_filing_day = toENCADateString(quoteData.intl_filing_day);
			}
			if (quoteData.intl_filing_date) {
				quoteData.intl_filing_date = toENCADateString(quoteData.intl_filing_date);
			}
			if (quoteData.draft) {
				quoteData.regions = quoteData.case_regions || [];
			}
		}
		return quoteData;
	};

	const showAllSectionsAction = () => {
		if (showAllSections) {
			setFooterBtnLabel('Next');
			setActiveIndex(1);
		} else {
			setFooterBtnLabel('Request Quote');
			setActiveIndex(0);
		}
		setShowAllSections((state) => !state);
	};

	/**
	 * The Additional Info tab is removed for the iPeer users. However, the activeIndex is not affected by this change.
	 * Which means that the Associate tab (for iPeer users) is going to have activeIndex === 6 eventhough it's on the 5th tab. And the Additional Info tab is going to keep its activeIndex, which is 5.
	 * And beecause of this change which is applied for the iPeer users, we need all the below given checks.
	 */
	const footerNext = async () => {
		if (requestSpinner) return;

		if (activeIndex < 4 && showAssociateSelection) {
			setActiveIndex((prevState) => prevState + 1);
		} else if (activeIndex === 4 && showAssociateSelection) {
			let validQuote = await validateQuote();
			if (!validQuote) return;
			setActiveIndex((prevState) => prevState + 2);
		} else if (activeIndex < 5 && !showAssociateSelection) {
			setActiveIndex((prevState) => prevState + 1);
		} else if ((activeIndex === 6 && showAssociateSelection) || (activeIndex === 5 && !showAssociateSelection)) {
			if (savedDraftId || draftQuote) {
				submitDraft();
			} else {
				submit();
			}
			return;
		}
	};

	useEffect(() => {
		if ((activeIndex === 6 && showAssociateSelection) || (activeIndex === 5 && !showAssociateSelection)) {
			if (currentLocation === location.ADD) {
				setFooterBtnLabel('Request Quote');
			} else {
				setFooterBtnLabel('Instruct Case');
			}
		}
	}, [activeIndex]);

	const handleClick = async (index) => {
		let i = index * 1;
		if (i === 6) {
			let validQuote = await validateQuote();
			if (!validQuote) return;
		}
		setActiveIndex(i);
		if ((i === 6 && showAssociateSelection) || (i === 5 && !showAssociateSelection)) {
			if (currentLocation === location.ADD) {
				setFooterBtnLabel('Request Quote');
			} else {
				setFooterBtnLabel('Instruct Case');
			}
		} else {
			setFooterBtnLabel('Next');
		}
	};

	const footerPrevious = () => {
		if (activeIndex === 6) {
			setActiveIndex(4);
		} else if (activeIndex > 1) {
			setActiveIndex((prevState) => prevState - 1);
		}
		setFooterBtnLabel('Next');
	};

	const headerCancel = () => {
		if (!redirect.isUpdated) {
			dispatch(setIsUpdated(redirectInit));
			history.push('/');
		}
		setCancelModal(true);
	};

	const closeCancelModal = () => {
		setCancelModal(false);
	};

	const getDocuments = async (id) => {
		let documentsData = await Documents.getByCase(id);
		// flag is_uploaded is added to detrmine  on update which documents are already uploaded
		documentsData = documentsData.map((d) => {
			d.is_uploaded = true;
			return d;
		});
		setDocuments(documentsData);
	};

	const uploadDocuments = async (qid) => {
		let quoteRegions = await QuotesRegions.GetAllByQuoteId(qid);
		// filter documents that are only for update
		let documentsUpdate = documents.filter((d) => d.is_uploaded === true && d.for_update === true);
		// filter documents that are not already uploaded
		// map the documents so we can do cleanup if some of the regions (with assigned document) is removed from the quote.
		let documentsCreate = documents
			.filter((d) => d.is_uploaded === false)
			.map((d) => {
				if (d.region_id !== 'all') {
					let exist = quoteRegions.data.some((r) => r.region_id === d.region_id);
					if (!exist) {
						d.region_id = 'all';
						d.object_type = currentLocation === location.INSTRUCT ? DOCUMENT_OBJECT_TYPE.CASE : DOCUMENT_OBJECT_TYPE.QUOTE;
					}
				}
				return d;
			});

		for (let d of documentsCreate) {
			// remap region id to quoteRegionID (needed for document object_id)
			const qr = quoteRegions.data.find((r) => r.region_id === d.region_id);

			// if region is removed but it has previous selected file for that region
			if (d.for_delete === true) {
				continue;
			}
			const payload = {
				object_type: d.object_type,
				object_id: d.object_type === DOCUMENT_OBJECT_TYPE.QUOTE_REGION || d.object_type === DOCUMENT_OBJECT_TYPE.CASE_REGION ? qr.id : qid,
				description: d.description,
				category: 'INITIAL_DOCUMENTS',
			};
			await Storage.DocumentUpload([d], payload);
		}
		for (let d of documentsUpdate) {
			await Documents.UpdateByID(d.id, d);
		}
	};

	const removeDocuments = async () => {
		let data = documents.filter((d) => d.for_delete === true);
		if (data.length === 0) {
			return;
		}
		for (let d of data) {
			await Documents.deleteByID(d.id);
		}
	};

	const validateQuote = async (validateAssociatesForRequest = false, validateAssociatesForInstruct = false, selectedRegionsForInstruct = []) => {
		let deadlineError = false;
		if (user.roleType === ROLE_TYPES.EXTERNAL) {
			let service = services.find((s) => s.id === quote.service_id);
			let date;
			if (service.key === SERVICE_KEYS.PCT) {
				date = quote.earliest_priority_date || quote.intl_filing_date;
			} else {
				date = quote.earliest_priority_date;
			}
			if (date) {
				deadlineError = validateInstructionsDeadline(new Date(date), quote.service_id, services, quote.priority_type);
			}
		}
		let result = validate(quote, services, user.roleType === ROLE_TYPES.EXTERNAL, user.role[0]);
		if (!result.isValid || deadlineError) {
			setErrors(result.errorFields);
			sendNotification({ type: 'error', title: 'Request Quote Failed. Fix errors above.' });
			setRequestSpinner(false);
			return false;
		}

		let duplicate = await checkDuplicates(quote.service_id, quote.application_number, quote.client_firm_id);
		if (duplicate) {
			setErrors({
				...errors,
				application_number: 'Already Existing',
			});
			setRequestSpinner(false);
			return false;
		}
		// validate if at least one regions have associate selected for request quote
		// When saas_user request quote check if there is at least one region that has associate selected
		if (validateAssociatesForRequest && user.role.includes('saas_user')) {
			let valid = quote.additional_region_info.some((r) => r.associate_id);
			if (!valid) {
				sendNotification({ type: 'error', title: 'Please Select Associate for at least one Region.' });
				setErrors({
					...errors,
					associate_tab: 'Missing Associate',
				});
				return false;
			}
		}
		// validate only the regions selectd for instructing, if they all have associate selected
		if (validateAssociatesForInstruct && user.role.includes('saas_user')) {
			let regionInfo = quote.additional_region_info.filter((r) => selectedRegionsForInstruct.includes(r.region_id));
			let invalid = regionInfo.some((r) => !r.associate_id);
			if (invalid) {
				sendNotification({ type: 'error', title: 'Please Select Associate for all Selected Regions.' });
				setErrors({
					...errors,
					associate_tab: 'Missing Associate',
				});
				return false;
			}
		}

		return true;
	};

	const submit = async (instructSelected = false, selectedRegionsForInstruct = []) => {
		setRequestSpinner(true);
		if (!agree) {
			sendNotification({ type: 'error', title: 'Request Quote Failed. Fix errors above.' });
			setErrors({
				...errors,
				agree_to_terms: ' ',
			});
			setRequestSpinner(false);
			return;
		}
		let validQuote = await validateQuote(!instructSelected, instructSelected, selectedRegionsForInstruct);
		if (!validQuote) {
			setRequestSpinner(false);
			return;
		}
		let res;
		let state = currentLocation === location.INSTRUCT ? 'CASE' : 'QUOTE';
		let newRegions = quote.regions;
		let regionInfo = quote.additional_region_info;
		if (instructSelected) {
			state = 'CASE';
			newRegions = newRegions.filter((r) => selectedRegionsForInstruct.includes(r));
			regionInfo = regionInfo.filter((r) => selectedRegionsForInstruct.includes(r.region_id));
		} else if (user.role.includes('saas_user')) {
			// when saas_user(ipeer) user request quote
			// filter the regions from regions info that do not have selected associate
			// also filter them from the regions array
			regionInfo = regionInfo.filter((r) => r.associate_id);
			newRegions = newRegions.filter((r) => regionInfo.some((ri) => ri.region_id === r));
		}
		let oon_associate = quote.additional_region_info.some((r) => r.firm_id && r.firm_id !== '0');
		try {
			res = await Quotes.Create({
				...quote,
				client_contact_email_for_quotes: formatEmailsStringToJSON(quote.client_contact_email_for_quotes),
				client_contact_bcc: formatEmailsStringToJSON(quote.client_contact_bcc),
				state: state,
				draft: false,
				oon_associate: oon_associate,
				regions: newRegions,
				additional_region_info: regionInfo,
			});
			let event = currentLocation === location.ADD ? QUOTE_EVENTS.REQUEST_QUOTE : QUOTE_EVENTS.IWOQ;

			await uploadDocuments(res.id);
			let estimate = await Estimates.CreateEstimate({
				quote: res,
			});

			if (estimate.attachment.s3key && estimate.attachment.filename && estimate.stop_rules.length === 0) {
				Base.OpenLink(`/storage/document/${estimate.attachment.filename}?key=${estimate.attachment.s3key}&download_type=inline`);
			}

			if (instructSelected) {
				let qrInstructed = 0;
				let promises = [];
				for (const r of res.additional_region_info) {
					if (r.firm_id === '0' || r.associate_id === '') continue;
					promises.push(QuotesRegions.sendEmailAgentInstructed(res.id, r));
					qrInstructed = 1;
				}
				if (promises.length) await Promise.all(promises);

				if (qrInstructed) {
					sendNotification({
						type: 'success',
						title: `Congratulations! We have received your instructions to file into these regions. We have emailed your chosen associates in each instructed region.`,
					});
				}
				EventLoggerEvents.EventLogger({ event: 'Save And Instruct', data: { user: user } });
			} else {
				EventLoggerEvents.EventLogger({ event: 'Save Quote But Do Not Instruct', data: { user: user } });
			}
			dispatch(setIsUpdated(redirectInit));
			setRequestSpinner(false);

			if (res.id) {
				let c = await Quotes.GetOne(res.id);
				if (user.roleType === ROLE_TYPES.EXTERNAL && (event === QUOTE_EVENTS.IWOQ || instructSelected)) {
					history.push(`/case-details/${c.id}`);
				} else if (user.roleType === ROLE_TYPES.EXTERNAL && event === QUOTE_EVENTS.REQUEST_QUOTE) {
					history.push(`/quotes?case-number=${c.case_number}`);
				} else {
					history.push(`/quotes/${c.id}/revisions`);
				}
			}
		} catch (err) {
			if (err?.code === 'ESTIMATE_NO_RULES_FOUND') {
				sendNotification({ type: 'error', title: 'No matching estimate rules were found!' });
			}

			if (!!instructSelected) {
				sendNotification({ type: 'error', title: getError(err.code) });
				let instrcutedRegions = regions.filter((r) => selectedRegionsForInstruct.includes(r.id));
				EventLoggerEvents.EventLogger({
					event: `Instruct Request Quote Failed`,
					data: { error: err?.message || err, user: user, instrcuted_regions: instrcutedRegions.map((r) => r.name).join(', ') },
				});
			} else {
				sendNotification({ type: 'error', title: 'Request Quote Failed, Please verify all fields and try again.' });

				let event = user.roleType === ROLE_TYPES.INTERNAL ? `Request Quote Failed on Admin` : `Request Quote Failed for Member`;
				EventLoggerEvents.EventLogger({ event: event, data: { user: user, error: err?.message } });
			}
			console.log(err);
			setRequestSpinner(false);
		}
	};

	const saveAsDraft = async () => {
		try {
			if (draftSpinner) return;
			setDraftSpinner(true);
			let state = currentLocation === location.INSTRUCT ? 'CASE' : 'QUOTE';
			if (savedDraftId || id) {
				let quoteId = id || savedDraftId;
				let res = await Quotes.UpdateDraftByID(quoteId, {
					...quote,
					state: state,
					draft: true,
					client_contact_email_for_quotes: formatEmailsStringToJSON(quote.client_contact_email_for_quotes),
					client_contact_bcc: formatEmailsStringToJSON(quote.client_contact_bcc),
				});
				// remove documents if associated region is removed
				await removeDocuments();
				// upload new documents
				await uploadDocuments(quoteId);
				// update shown documents
				await getDocuments(quoteId);

				/**
				 * For the iPeer users, the Additional Info tab is removed, and now the Associates tab is basically the fifth tab.
				 * However, the activeIndex is not changed for either tab, whuch is why we need this check, so that we know on which step exactly we are.
				 */
				const tabIndex = activeIndex === 6 ? '5' : activeIndex;
				EventLoggerEvents.EventLogger({ event: `Draft Updated on Quote Wizard Step ${tabIndex}`, data: { user: user } });
			} else {
				let res = await Quotes.CreateDraft({
					...quote,
					state: state,
					draft: true,
					client_contact_email_for_quotes: formatEmailsStringToJSON(quote.client_contact_email_for_quotes),
					client_contact_bcc: formatEmailsStringToJSON(quote.client_contact_bcc),
					oon_associate: DOMAINS.IPEER.includes(btoa(domain)),
				});

				if (res.id) {
					dispatch(setQuoteDataFromObject(res));
					setSavedDraftId(res.id);
					await uploadDocuments(res.id);
					await getDocuments(res.id);

					/**
					 * For the iPeer users, the Additional Info tab is removed, and now the Associates tab is basically the fifth tab.
					 * However, the activeIndex is not changed for either tab, whuch is why we need this check, so that we know on which step exactly we are.
					 */
					const tabIndex = activeIndex === 6 ? '5' : activeIndex;
					EventLoggerEvents.EventLogger({ event: `Draft Saved on Quote Wizard Step ${tabIndex}`, data: { user: user } });
				}
			}

			sendNotification({
				type: 'success',
				title: `Draft saved. Check 'Saved Quotes' in the top nav.`,
			});
			setDraftSpinner(false);
		} catch (err) {
			console.log(err);
			setDraftSpinner(false);
		}
	};

	const submitDraft = async (instructSelected = false, selectedRegionsForInstruct = []) => {
		setRequestSpinner(true);
		let validQuote = await validateQuote(!instructSelected, instructSelected, selectedRegionsForInstruct);
		if (!validQuote) {
			setRequestSpinner(false);
			return;
		}
		try {
			let state = currentLocation === location.INSTRUCT ? 'CASE' : 'QUOTE';
			let quote_id = id || savedDraftId;
			let newRegion = quote.regions;
			let regionInfo = quote.additional_region_info;
			if (instructSelected) {
				state = 'CASE';
				newRegion = newRegion.filter((r) => selectedRegionsForInstruct.includes(r));
				regionInfo = regionInfo.filter((r) => selectedRegionsForInstruct.includes(r.region_id));
			}
			let oon_associate = quote.additional_region_info.some((r) => r.firm_id && r.firm_id !== '0');
			let res = await Quotes.UpdateDraftByID(quote_id, {
				...quote,
				client_contact_email_for_quotes: formatEmailsStringToJSON(quote.client_contact_email_for_quotes),
				client_contact_bcc: formatEmailsStringToJSON(quote.client_contact_bcc),
				state: state,
				draft: false,
				oon_associate: oon_associate,
				regions: newRegion,
				additional_region_info: regionInfo,
			});
			await removeDocuments();
			await uploadDocuments(quote_id);
			if (res.exchange_rate_date) {
				res.exchange_rate_date = toENCADateString(res.exchange_rate_date);
			}
			let estimate = await Estimates.CreateEstimate({
				quote: { ...res, client_id: res.client_id, id: quote_id },
			});

			if (estimate.attachment.s3key && estimate.attachment.filename) {
				Base.OpenLink(`/storage/document/${estimate.attachment.filename}?key=${estimate.attachment.s3key}&download_type=inline`);
			}
			dispatch(setIsUpdated(redirectInit));
			setRequestSpinner(false);

			if (res) {
				if (user.roleType === ROLE_TYPES.EXTERNAL) {
					history.push(`/quotes/${quote_id}/estimate`);
				} else {
					history.push(`/quotes/${quote_id}/revisions`);
				}
			}
		} catch (err) {
			if (err?.code === 'ESTIMATE_NO_RULES_FOUND') {
				sendNotification({ type: 'error', title: 'No matching estimate rules were found!' });
			}
			console.log(err);
			setRequestSpinner(false);
		}
	};

	const saveAsDraftBeforeClose = () => {
		saveAsDraft();
		dispatch(setIsUpdated(redirectInit));
		history.push('/');
	};

	const cancelModalContinue = () => {
		EventLoggerEvents.EventLogger({ event: 'Quote Abandoned', data: { user: user } });
		dispatch(setIsUpdated(redirectInit));
		history.push('/');
	};

	const cancelModalFooterActions = [
		{ primary: true, label: 'Save as Draft', action: saveAsDraftBeforeClose, theme: 'bordered' },
		{ primary: true, label: 'Proceed without Saving', action: cancelModalContinue, theme: 'azami-blue' },
	];

	const instructionDeadlineModalClose = () => {
		setInstructionDeadlineModal(false);
	};

	const instructionDeadlineModalCancel = [{ primary: true, label: 'Cancel', action: instructionDeadlineModalClose, theme: 'azami-ghost' }];

	const getServiceKey = (service_id) => {
		let service = services.find((s) => s.id === service_id);
		return service?.key;
	};

	const validateInstructionsDeadline = (date, service_id, services, priority_type) => {
		let invalidDeadline = validateDeadline(new Date(date), service_id, services, priority_type);
		if (invalidDeadline) {
			setInstructionDeadlineModal(true);
		}
		return invalidDeadline;
	};

	const generateClientSpecialInstructionNote = async () => {
		let clientData = await Users.GetOneSlim(user.uid);
		let userInitials = `${clientData.first_name[0]}.${clientData.last_name[0]}.`;
		let noteHeader = '';
		let today_date = new Date();
		let date = today_date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
		if (user.roleType === ROLE_TYPES.EXTERNAL) noteHeader = 'Member Created\n';
		let note = `${noteHeader}Incomplete:\nSmall/Micro Entity: default\nRussia Unsearchable Claims:\n(${userInitials} - ${date})`;
		return note;
	};

	const checkDuplicates = async (service_id, application_number, client_firm_id) => {
		// allow duplicates with defined keywords
		if (APP_NUM_IGNORED_KEY_WORDS.includes(quote.application_number.toUpperCase())) return;
		let qData = {
			application_number: application_number,
			service_id: service_id,
			client_firm_id: client_firm_id,
		};
		try {
			const q = await Quotes.GetByClientsFirm(qData);
			if (q) {
				// check for edit mode on existing quote
				if (id && quote.application_number === q.application_number && q.id === quote.id) return;
				setDuplicateQuote(q);
				duplicatesModalOpenHandler();
				return true;
			}
		} catch (err) {
			if (err === 'Not Found' && duplicateQuote.application_number) {
				setDuplicateQuote(duplicateQuoteInit);
				setDuplicatesModalOpen(false);
				return;
			}
			console.log(err);
		}
	};

	const duplicatesModalOpenHandler = () => {
		setDuplicatesModalOpen(true);
	};

	const goToDetails = () => {
		history.push(`/quotes/${duplicateQuote.id}`);
	};

	const duplicatesModalCloseHandler = () => {
		setDuplicatesModalOpen(false);
	};

	const duplicatesModalFooterActions = [
		{ primary: false, label: 'Continue', action: goToDetails, theme: 'azami-blue' },
		{ primary: true, label: 'Cancel', action: duplicatesModalCloseHandler, theme: 'azami-ghost' },
	];

	const instructCheckboxChange = (e) => {
		let regions = [];
		if (!e.target.checked) {
			regions = [...selectedRegionsForInstruct.filter((or) => or !== e.target.value)];
		} else {
			regions = [...selectedRegionsForInstruct, e.target.value];
		}
		setSelectedRegionsForInstruct(regions);
	};

	const showInstructModal = () => {
		setInstructModal(true);
	};
	const hideInstructModal = () => {
		setInstructModal(false);
	};
	const instruct = () => {
		if (savedDraftId || draftQuote) {
			submitDraft(true, selectedRegionsForInstruct);
		} else {
			submit(true, selectedRegionsForInstruct);
		}
	};

	return (
		<div className={'main-screen-section white no-padding quote-details'}>
			<div className="main-screen-section__details-content__body quote-details">
				<AppTemplate title={`${currentLocation === location.ADD ? 'Request Quote' : 'Instruct Without A Pre-Existing Quote'}`} headerIcon="quotes">
					<AppTemplate.Header>
						<QuoteActionsHeader saveAsDraft={saveAsDraft} headerCancel={headerCancel} draftSpinner={draftSpinner} />
					</AppTemplate.Header>
					<AppTemplate.Content>
						<LoaderOverlay showLoader={showLoader} />
						<div>
							<QuoteWizardNavigation
								showAllSections={showAllSections}
								activeIndex={activeIndex}
								handleClick={handleClick}
								errors={errors}
								showAssociateSelection={showAssociateSelection}
								showAdditionalInfoSelection={showAdditionalInfoSelection}
							/>
							<div className="new-quote-wizard__content">
								{activeIndex == 1 || showAllSections ?
									<Services
										services={services}
										technologiesData={technologies}
										errors={errors}
										validateInstructionsDeadline={validateInstructionsDeadline}
										checkDuplicates={checkDuplicates}
										setErrors={setErrors}
										newQuote={true}
									/>
								:	null}
								{activeIndex == 2 || showAllSections ?
									<Identifiers
										services={services}
										errors={errors}
										setErrors={setErrors}
										newQuote={true}
										languages={languages}
										firms={firms}
										clients={clients}
										setClients={setClients}
										checkDuplicates={checkDuplicates}
									/>
								:	null}
								{activeIndex == 3 || showAllSections ?
									<QuoteRegionPicker
										regions={regions}
										services={services}
										errors={errors}
										languages={languages}
										selectedServiceKey={getServiceKey(quote.service_id)}
										setSelectedRegionsForInstruct={setSelectedRegionsForInstruct}
										selectedRegionsForInstruct={selectedRegionsForInstruct}
									/>
								:	null}
								{activeIndex == 4 || showAllSections ?
									<Details validateInstructionsDeadline={validateInstructionsDeadline} services={services} languages={languages} regions={regions} errors={errors} newQuote={true} />
								:	null}
								{activeIndex == 5 || showAllSections ?
									<AdditionalInformation
										uploadTable={true}
										instructWithoutQuote={currentLocation === location.ADD ? false : true}
										regions={regions}
										errors={errors}
										documents={documents}
										setDocuments={setDocuments}
										objectTypeInit={currentLocation === location.ADD ? DOCUMENT_OBJECT_TYPE.QUOTE : DOCUMENT_OBJECT_TYPE.CASE}
									/>
								:	null}
								{(activeIndex == 6 && showAssociateSelection) || (showAllSections && showAssociateSelection) ?
									<AssociateRecommender
										regions={regions}
										languages={languages}
										selectedRegionsForInstruct={selectedRegionsForInstruct}
										setSelectedRegionsForInstruct={setSelectedRegionsForInstruct}
										services={services}
										user={user}
										currencies={currencies}
										errors={errors}
									/>
								:	null}
								<QuoteActionsFooter
									footerNext={footerNext}
									footerBtnLabel={footerBtnLabel}
									footerPrevious={footerPrevious}
									activeIndex={activeIndex}
									requestSpinner={requestSpinner}
									showInstructModal={showInstructModal}
								/>
							</div>
							{instructionDeadlineModal ?
								<InstructionDeadlineModal
									designService={getServiceKey(quote.service_id) === SERVICE_KEYS.DESIGN}
									footerActions={instructionDeadlineModalCancel}
									closeHandler={instructionDeadlineModalClose}
								/>
							:	null}
							{duplicatesModalOpen ?
								<QuoteDuplicateApplicationNumberModal duplicatesModalFooterActions={duplicatesModalFooterActions} duplicateQuote={duplicateQuote} />
							:	null}
							{cancelModal ?
								<Modal title="Confirm Before Exiting" closeHandler={closeCancelModal} footerActions={cancelModalFooterActions}>
									You're about to leave the quote request. Would you like to save your progress <br />
									as a draft or discard your changes?
								</Modal>
							:	null}
							{instructModal ?
								<NewQuoteInstructModal
									regions={regions}
									closeHandler={hideInstructModal}
									checkboxChange={instructCheckboxChange}
									documents={documents}
									setDocuments={setDocuments}
									instruct={instruct}
									selectedRegionsForInstruct={selectedRegionsForInstruct}
									setSelectedRegionsForInstruct={setSelectedRegionsForInstruct}
								/>
							:	null}
						</div>
					</AppTemplate.Content>
				</AppTemplate>
			</div>
		</div>
	);
};

export default NewQuote;
