import Ajax from './base';

const GetAllRules = (queryStringData) => {
	return Ajax.Request('/estimates/rules', Ajax.GET, true, queryStringData);
};

const CreateRule = (data) => {
	return Ajax.Request('/estimates/rules', Ajax.POST, true, data);
};

const UpdateRule = (id, data) => {
	return Ajax.Request(`/estimates/rules/${id}`, Ajax.PUT, true, data);
};

const GetOneRulesByID = (id) => {
	return Ajax.Request(`/estimates/rules/${id}`, Ajax.GET, true);
};

const DeleteRule = (id, calculationObject) => {
	return Ajax.Request(`/estimates/rules/${id}/${calculationObject}`, Ajax.DELETE, true);
};

const UnarchiveRule = (id) => {
	return Ajax.Request(`/estimates/rules/${id}`, Ajax.PATCH, true);
};

const DeleteRuleByParentId = (parentId, calculationObject) => {
	return Ajax.Request(`/estimates/rules/parent/${parentId}/${calculationObject}`, Ajax.DELETE, true);
};

const UnarchiveRuleByParentId = (parentId) => {
	return Ajax.Request(`/estimates/rules/parent/${parentId}`, Ajax.PATCH, true);
};

const CreateEstimate = (data) => {
	return Ajax.Request('/estimates/calculation', Ajax.POST, true, data);
};

const GetEstimateRulesForQuote = (id, queryStringData) => {
	return Ajax.Request(`/estimates/calculation/quote/${id}/rules`, Ajax.GET, true, queryStringData);
};

const GetAllEstimatesById = (id) => {
	return Ajax.Request(`/estimates/quote/${id}`, Ajax.GET, true);
};

const GetLastPublishedEstimateByQuoteId = (id) => {
	return Ajax.Request(`/estimates/quote/${id}/last-published`, Ajax.GET, true);
};

const GetLastEstimateByQuoteId = (id) => {
	return Ajax.Request(`/estimates/quote/${id}/last-estimate`, Ajax.GET, true);
};

const GetOONForEstimate = (id, queryStringData) => {
	return Ajax.Request(`/estimates/associates/${id}`, Ajax.GET, true, queryStringData);
};

const GetAllEstimateRules = (id, queryStringData) => {
	return Ajax.Request(`/estimates/${id}/rules`, Ajax.GET, true, queryStringData);
};

const CreateOverrideFees = (data) => {
	return Ajax.Request('/estimates/overrides', Ajax.POST, true, data);
};

const UpdateOverrideFees = (data) => {
	return Ajax.Request('/estimates/overrides', Ajax.PUT, true, data);
};

const getOverrideFees = (estimate_id, queryStringData) => {
	return Ajax.Request(`/estimates/overrides/${estimate_id}`, Ajax.GET, true, queryStringData);
};

const SendEmail = (quote_id, estimate_id) => {
	return Ajax.Request(`/estimates/${quote_id}/email/${estimate_id}`, Ajax.POST, true);
};

const SyncToCAS = (case_number, state) => {
	return Ajax.Request(`/estimates/cas/${case_number}/${state}`, Ajax.GET, true);
};

const PublishEstimate = (id, payload) => {
	return Ajax.Request(`/estimates/${id}/published`, Ajax.PUT, true, payload);
};

const CreatePreCalculation = (payload) => {
	return Ajax.Request(`/estimates/pre-calculation`, Ajax.POST, true, payload);
};

export default {
	GetAllRules,
	CreateRule,
	UpdateRule,
	GetOneRulesByID,
	DeleteRule,
	UnarchiveRule,
	DeleteRuleByParentId,
	UnarchiveRuleByParentId,
	CreateEstimate,
	GetAllEstimatesById,
	GetAllEstimateRules,
	CreateOverrideFees,
	UpdateOverrideFees,
	getOverrideFees,
	GetEstimateRulesForQuote,
	SendEmail,
	SyncToCAS,
	GetLastPublishedEstimateByQuoteId,
	PublishEstimate,
	CreatePreCalculation,
	GetLastEstimateByQuoteId,
	GetOONForEstimate,
};
