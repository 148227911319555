import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { API_HOST, ROLE_TYPES, QUOTE_VALIDATION_ERROR } from 'data/constants';
import Input from 'com/ui/Input';
import Select from 'com/ui/Select';
import Checkbox from 'com/ui/Checkbox';
import Button from 'com/ui/Button';
import LetterBadge from 'com/ui/LetterBadge';

import DataTable from 'com/widgets/DataTable';
import { ToolTip } from 'com/widgets/DocumentsTable';
import { RoleBox } from 'com/util/RoleBox';
import LoaderOverlay from 'com/ui/LoaderOverlay';
import AppDate from 'com/util/AppDate';

import useNotification from 'services/hooks/use_notification';

// import QuoteDetailsViewRevision from './QuoteDetailsViewRevision';
import QuotesDetailsViewOverridesModal from './QuotesDetailsViewOverridesModal';
import QutesDetailsViewModifyCurrencyModal from './QutesDetailsViewModifyCurrencyModal';
import QuoteDetailsViewRevisionsPublishModal from './QuoteDetailsViewRevisionsPublishModal';
import QuotesDetailsViewExchangeRatesModal from './QuotesDetailsViewExchangeRatesModal';

import PricingLevel from 'services/rest/pricing_levels';
import Quotes from 'services/rest/quotes';
import Estimates from 'services/rest/estimates';
import Settings from 'services/rest/settings';
import Documents from 'services/rest/documents';
import Reports from 'services/rest/reports';
import Base from 'services/rest/base';
import EventLoggerEvents from 'services/rest/event_logger';
import Firms from 'services/rest/firms';
import Users from 'services/rest/users';
import { toLocaleTimeString, toENCADateString } from 'services/dates';
import { formatNumberWithSeparator, roundUp } from 'services/strings';
import { DOCUMENT_OBJECT_TYPE, ESTIMATOR_RULE_LEVELS, ESTIMATE_STATUS, INTERNAL_ROLES, PAGE_TITLE, RULE_LEVEL } from 'data/constants';

import useTitle from 'services/hooks/useTitle';

import ICON_CALENDAR from 'assets/images/icons/ico_calendar_azami.svg';
import ICON_CLOCK from 'assets/images/icons/ico_clock_blurple.svg';
import ICON_ACTIONS_MENU_BLURPLE from 'assets/images/icons/ico_actions_menu_blurple.svg';
import ICON_PRICING_LEVEL from 'assets/images/icons/ico_pricing_level.svg';
import ICON_EXCHANGE_RATES from 'assets/images/icons/ico_exchange_rate.svg';
import ICON_DUPLICATE_QUOTE from 'assets/images/icons/ico_duplicate_quote.svg';
import ICON_OVERRIDE_FEES from 'assets/images/icons/ico_override_fees.svg';
import ICON_EMAIL from 'assets/images/icons/ico_send_email.svg';
import ICON_CONVERT_CURRENCY from 'assets/images/icons/ico_convert _currency.svg';
import ICON_GEAR_WHITE from 'assets/images/icons/ico_gear_white.svg';
import ICON_ARROW_DOWN from 'assets/images/icons/ico_sort_down_white.svg';
import ICON_PDF from 'assets/images/icons/ico_pdf_estimate_action_menu.svg';
import ICON_PDF_EXPORT from 'assets/images/icons/ico_pdf_export.svg';
import ICON_EXCEL from 'assets/images/icons/ico_excel_estimate_action_menu.svg';
import ICON_DOWNLOAD from 'assets/images/icons/ico-download.svg';
import ICON_ARROW_UP_BLURPLE from 'assets/images/icons/ico_sort_up.svg';
import ICON_ARROW_DOWN_BLURPLE from 'assets/images/icons/ico_sort_down.svg';
import ICON_INFO from 'assets/images/icons/ico_toast_info_violet.svg';
import ICON_EYE_VISIBLE from 'assets/images/icons/ico_eye_visible.svg';
import ICON_EYE_NOT_VISIBLE from 'assets/images/icons/ico_eye_not_visible.svg';
import ICON_PUSH_TO_SYSTEM from 'assets/images/icons/ico_push_to_system_estimate_action_menu.svg';
import ICON_PUBLISH from 'assets/images/icons/ico_blue_circle_estimate_action_menu.svg';
import ICON_STOP_RULE from 'assets/images/icons/ico_stop_rule.svg';
import ICON_FAIL_RULE from 'assets/images/icons/ico_failed_rule.svg';

import './style.css';

const QuoteDetailsViewQuoteRevisions = (props) => {
	useTitle(PAGE_TITLE.QUOTE_DETAILS);
	const { id } = useParams();
	const sendNotification = useNotification();

	const rulesTableHeaders = [
		{ title: 'Rule Name', field: 'regions', type: 'string', sort: 'none', sortable: true },
		{ title: 'Rule Level', field: 'professional_fee', type: 'string', sort: 'none', sortable: true },
		{ title: 'Fee', field: 'translation_fee', type: 'string', sort: 'none', sortable: true },
		{ title: 'Region', field: 'official_fee', type: 'string', sort: 'none', sortable: true },
		{ title: 'Amount', field: 'total_fees', type: 'string', sort: 'none', sortable: true },
		{ title: '', field: 'lables', type: 'string', sort: 'none', sortable: false },
	];

	const feesTableHeaders = [
		{ title: 'Region', field: 'regions', type: 'string', sort: 'none', sortable: true },
		{ title: 'Associate', field: 'associate', type: 'string', sort: 'none', sortable: true },
		{ title: 'Filing Fee', field: 'professional_fee', type: 'string', sort: 'none', sortable: true },
		{ title: 'Translation Fee', field: 'translation_fee', type: 'string', sort: 'none', sortable: true },
		{ title: 'Official Fee', field: 'official_fee', type: 'string', sort: 'none', sortable: true },
		{ title: 'Total', field: 'total_fees', type: 'string', sort: 'none', sortable: true },
		{ title: 'Complete', field: 'region_complete', type: 'bool', sort: 'none', sortable: true },
		// { title: '', field: 'associate_firm_name', type: 'string', sort: 'none', sortable: false},
		{ title: '', field: 'hide_region', type: 'string', sort: 'none', sortable: false },
	];

	const badgeColors = {
		PROFESSIONAL: '#026EB3',
		OFFICIAL: '#10B5B5',
		TRANSLATION: '#BD2FEE',
	};

	const filterInit = {
		rule_level: '',
		name: '',
		region: '',
		fee_type: '',
		// orderBy: [],
		rule_name: '',
		service: '',
	};

	const feeTypes = [
		{ label: 'All', value: '' },
		{ label: 'Filing', value: 'PROFESSIONAL' },
		{ label: 'Official', value: 'OFFICIAL' },
		{ label: 'Tranlation', value: 'TRANSLATION' },
	];

	const [pricingLevels, setPricingLevels] = useState([]);
	const [professionalFee, setProfessionalFee] = useState('');
	const [translationFee, setTranslationFee] = useState('');
	const [data, setData] = useState('');
	const [estimates, setEstimates] = useState([]);
	const [priceTableData, setPriceTableData] = useState({});
	const [ruleTableData, setRuleTableData] = useState({});
	const [filters, setFilters] = useState(filterInit);
	const [activeIndex, setActiveIndex] = useState('');
	const [completeQuote, setCompleteQuote] = useState(false);
	const [overridesModal, setOverridesModal] = useState(false);
	const [overridesModalEstimateId, setOverridesModalEstimateId] = useState('');
	const [documents, setDocuments] = useState([]);
	const [usedCurrency, setUsedCurrency] = useState('');
	const [modifyCurrencyModal, setModifyCurrencyModal] = useState(false);
	const [currencies, setCurrencies] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [lastOverrideFees, setLastOverrideFees] = useState([]);
	const [publishModal, setPublishModal] = useState(false);
	const [modifyExchangeRates, setModifyExchangeRates] = useState(false);
	const [requestQuoteBtnDisabled, setRequestQuoteBtnDisabled] = useState(false);

	const history = useHistory();
	const user = useSelector((state) => state.auth.user);

	useEffect(() => {
		(async () => {
			try {
				setRequestQuoteBtnDisabled(true);
				let pricingLevelsData = await PricingLevel.GetAll({ show_is_archived: '0' });
				setPricingLevels([
					...pricingLevelsData.map((p) => ({
						// label: `${p.name} (${p.value}%)`,
						label: `${p.name} (${p.diff_from_base}%)`,
						value: p.id,
					})),
				]);

				let currencies = await Settings.GetCurrencies();
				setCurrencies(currencies);
				if (id) {
					let [quoteData, est, documentsData] = await Promise.all([Quotes.GetOne(id), Estimates.GetAllEstimatesById(id), Documents.getByCase(id)]);
					if (quoteData && quoteData.client_id) {
						let user = await Users.GetOneSlim(quoteData.client_id);
						let firm = await Firms.GetOneByIDSlim(user.firm_id);
						if (firm && firm.currency_id) {
							let currency = await Settings.GetExchangeRates();
							if (currency.some((c) => c.currency_id === firm.currency_id)) {
								setUsedCurrency(firm.currency_id);
							} else {
								let c = currencies.find((c) => c.code === 'USD');
								if (c) {
									setUsedCurrency(c.id);
								}
							}
						} else {
							let c = currencies.find((c) => c.code === 'USD');
							setUsedCurrency(c.id);
						}
					}
					if (est.length > 0) {
						setEstimates(est);
						let last = est[0];
						setProfessionalFee(last.professional_fee_pricing_level_id);
						setTranslationFee(last.translation_fee_pricing_level_id);
					}
					if (quoteData && quoteData.exchange_rate_date) {
						if (quoteData.exchange_rate_date) {
							quoteData.exchange_rate_date = toENCADateString(quoteData.exchange_rate_date);
						}
					}
					setData(quoteData);
					setDocuments(documentsData);
				}
				setRequestQuoteBtnDisabled(false);
			} catch (err) {
				setRequestQuoteBtnDisabled(false);
				console.log(err);
			}
		})();
	}, []);

	// Check if all estimate regions are completed, if they are, set "Quote Complete" to true.
	useEffect(() => {
		let d = priceTableData[estimates[0]?.id];
		estimates[0]?.id && d?.every((d) => d.complete) ? setCompleteQuote(true) : setCompleteQuote(false);
	}, [priceTableData[estimates[0]?.id]?.length, data.completed_regions?.length]);

	useEffect(() => {
		if (!estimates[0]) return;
		(async () => {
			try {
				await prepEstimateData(estimates[0].id);
			} catch (error) {
				console.log('error', error);
			}
		})();
	}, [data.id]);

	const pricingLevelLabel = (level_id) => {
		// let level = pricingLevels.find(l=> l.value === level_id);
		let level = pricingLevels.find((l) => l.diff_from_base === level_id);
		if (level) {
			return level.label;
		} else {
			return '';
		}
	};

	const onFilterChange = (e) => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value,
		});
	};

	const professionalFeeChange = (e) => {
		setProfessionalFee(e.target.value);
	};

	const translationFeeChange = (e) => {
		setTranslationFee(e.target.value);
	};

	const showExchangeRatesModal = () => {
		console.log('exchange rates');
	};

	const closeExchangeRatesModal = () => {
		console.log('close exchange rates');
	};

	const showOverrideFeesModal = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		setOverridesModalEstimateId(e.target.closest('li').dataset.id);
		setOverridesModal(true);
	};

	const closeOverrideFeesModal = () => {
		setOverridesModal(false);
	};

	const showModifyCurrencyModal = async (e) => {
		setModifyCurrencyModal(true);
	};

	const closeModifyCurrencyModal = () => {
		setModifyCurrencyModal(false);
	};

	const pushToCAS = async (e) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			Estimates.SyncToCAS(data.case_number, data.state);
			sendNotification({ type: 'success', title: 'Push to CAS started. You must visually verify on CAS.' });
		} catch (err) {
			sendNotification({ type: 'error', title: 'Push to CAS Failed.' });
		}
	};

	const sendEmailToAE = async (e) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			let estimate_id = e.target.closest('details').dataset.id;
			await Estimates.SendEmail(id, estimate_id);
			sendNotification({ type: 'success', title: 'Successfully sent email to AE' });
		} catch (err) {
			sendNotification({ type: 'error', title: 'Unsuccessfully sent email to AE' });
		}
	};

	const showPublishModal = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		setPublishModal(true);
	};

	const closePublishModal = () => {
		setPublishModal(false);
	};

	const handleRequestQuote = async () => {
		setShowLoader(true);
		let hiddenRegions = [];
		let priceTableKeys = Object.keys(priceTableData);
		let last_overrides_fees = [];
		let lastEstimateAppliedRules = [];
		if (estimates.length > 0) {
			if (lastOverrideFees.length > 0) {
				last_overrides_fees = lastOverrideFees;
			} else {
				last_overrides_fees = await Estimates.getOverrideFees(estimates[0].id);
			}
			hiddenRegions = priceTableData[estimates[0].id];
			lastEstimateAppliedRules = await Estimates.GetAllEstimateRules(estimates[0].id, { currency: usedCurrency });
		}
		// get estimate rules for latest estimate if details is not expanded (data is not previously fetched)
		if (estimates.length > 0 && priceTableKeys.length === 0 && lastEstimateAppliedRules.length > 0) {
			hiddenRegions = lastEstimateAppliedRules;
		}

		hiddenRegions = hiddenRegions.filter((f) => f.is_visible === 0).map((d) => d.region_id);
		let lastAppliedRules = [];
		if (lastEstimateAppliedRules.length > 0) {
			lastAppliedRules = lastEstimateAppliedRules.map((r) => {
				return {
					id: r.rule_id,
					region_id: r.region_id,
				};
			});
		}
		let additional_region_info = [];
		if (estimates[0].oon_associate) {
			let oon_pricing = await Estimates.GetOONForEstimate(estimates[0].id);
			if (oon_pricing.length > 0) {
				oon_pricing.map((op) => {
					additional_region_info.push({
						region_id: op.region_id,
						associate_id: op.associate_id,
						rate_official: op.official_fee,
						rate_professional: op.professional_fee,
						rate_translation: op.translation_fee,
					});
				});
			}
		}

		let new_overrides = [];
		// If estimate created and last estimate has overrides on fees transfer the overrides fees to the new estimate
		if (last_overrides_fees.length > 0) {
			//remove overrides for regions that have been removed from the quote
			last_overrides_fees = last_overrides_fees.filter((o) => {
				return data.regions.some((r) => {
					return r === o.region_id;
				});
			});

			new_overrides = last_overrides_fees.map((o) => {
				return {
					region_id: o.region_id,
					professional_fee: o.professional_fee,
					official_fee: o.official_fee,
					translation_fee: o.translation_fee,
				};
			});

			//add newly added regions to existing overrides for the quote
			new_overrides = data.regions.map((o) => {
				let region = new_overrides.find((r) => r.region_id === o);
				if (region) {
					return region;
				} else {
					return {
						region_id: o,
						professional_fee: null,
						official_fee: null,
						translation_fee: null,
					};
				}
			});
		}

		try {
			let ne = await Estimates.CreateEstimate({
				quote: { ...data, additional_region_info },
				applied_rules: lastAppliedRules,
				overrides: {
					professional_fee_pricing_level_id: professionalFee,
					translation_fee_pricing_level_id: translationFee,
					hidden_regions: hiddenRegions,
				},
				overridesFees: new_overrides,
			});

			if (ne.attachment.s3key && ne.attachment.filename) {
				Base.OpenLink(`/storage/document/${ne.attachment.filename}?key=${ne.attachment.s3key}&download_type=inline`);
			}

			refreshEstimatesData();
			setLastOverrideFees([]);
			setShowLoader(false);
		} catch (err) {
			if (err?.code === 'ESTIMATE_NO_RULES_FOUND') {
				sendNotification({ type: 'error', title: 'No matching estimate rules were found!' });
			}
			console.log(err);
			setShowLoader(false);
		}
	};

	const refreshEstimatesData = async (used_currency = '') => {
		let est = await Estimates.GetAllEstimatesById(id);
		setEstimates(est);
		await prepEstimateData(est[0].id, used_currency);
	};

	const estimateToggle = async (e) => {
		if (activeIndex === e.target.dataset.id) {
			setActiveIndex('');
		} else {
			setActiveIndex(e.target.dataset.id);
		}

		if (e.target.open) {
			await prepEstimateData(e.target.dataset.id);
		}
		return;
	};

	const prepEstimateData = async (estimate_id, used_currency = '') => {
		try {
			let currency = used_currency ? used_currency : usedCurrency;
			let overrides = lastOverrideFees.length > 0 && estimate_id === estimates[0].id ? lastOverrideFees : await Estimates.getOverrideFees(estimate_id);
			let res = await Estimates.GetAllEstimateRules(estimate_id, { currency: currency });
			// table 1 - pricings
			let pricings = pricingTransform(res, overrides);
			let rules = rulesTransform(res);
			let selectedEstimate = estimates.find((e) => e.id === estimate_id);
			if (selectedEstimate?.oon_associate) {
				let oon_pricing = await Estimates.GetOONForEstimate(estimate_id, { currency: currency });
				if (oon_pricing.length > 0) {
					oon_pricing.map((op) => {
						let overrideRegion = null;
						if (overrides && overrides.length > 0) {
							overrideRegion = overrides.find((o) => o.region_id === op.region_id);
						}
						let official_fee = overrideRegion?.official_fee ? overrideRegion.official_fee : op.official_fee;
						let professional_fee = overrideRegion?.professional_fee ? overrideRegion.professional_fee : op.professional_fee;
						let translation_fee = overrideRegion?.translation_fee ? overrideRegion.translation_fee : op.translation_fee;
						pricings.push({
							firm_name: op.firm_name,
							region_id: op.region_id,
							complete: false,
							is_visible: 1,
							official_fee: roundUp(official_fee),
							professional_fee: roundUp(professional_fee),
							translation_fee: roundUp(translation_fee),
							region_name: op.region_name,
							total: roundUp(professional_fee) + roundUp(translation_fee) + roundUp(official_fee),
							professional_fee_override: overrideRegion?.professional_fee ? true : false,
							official_fee_override: overrideRegion?.official_fee ? true : false,
							translation_fee_override: overrideRegion?.translation_fee ? true : false,
							region_code: op.region_code,
						});
					});
				}
			}
			setPriceTableData((state) => {
				return {
					...state,
					[estimate_id]: pricings,
				};
			});
			setRuleTableData((state) => {
				return {
					...state,
					[estimate_id]: rules,
				};
			});
			// console.log(pricings);
			// table 2 - applied rules
		} catch (err) {
			console.log(err);
		}
	};

	const currencyIdToSymbol = (id) => {
		let cs = currencies.find((c) => c.id === id);
		return cs ? cs.symbol : '';
	};

	const pricingTransform = (pdata, overrides) => {
		let cout = {};
		for (let d of pdata) {
			if (!cout[d.region_code]) {
				cout[d.region_code] = {
					region_code: d.region_code,
					region_id: d.region_id,
					region_name: d.region_name,
					professional_fee: 0,
					translation_fee: 0,
					official_fee: 0,
					is_visible: d.is_visible,
					total: 0,
					professional_fee_override: false,
					translation_fee_override: false,
					official_fee_override: false,
					complete: data.completed_regions?.includes(d.region_id),
				};
			}
			switch (d.fee_type) {
				case 'PROFESSIONAL':
					cout[d.region_code].professional_fee += Number(d.fee_value);
					break;
				case 'TRANSLATION':
					cout[d.region_code].translation_fee += Number(d.fee_value);
					break;
				case 'OFFICIAL':
					cout[d.region_code].official_fee += Number(d.fee_value);
					break;
			}
			cout[d.region_code].total += Number(d.fee_value);
		}

		for (let i in cout) {
			cout[i].professional_fee = roundUp(cout[i].professional_fee);
			cout[i].translation_fee = roundUp(cout[i].translation_fee);
			cout[i].official_fee = roundUp(cout[i].official_fee);
			cout[i].total = roundUp(cout[i].professional_fee + cout[i].translation_fee + cout[i].official_fee);
		}

		// for(let i in cout) {
		//     if(cout[i].total % 5 !== 0) {
		//         cout[i].total += 5 - (cout[i].total % 5);
		//     }
		// }

		let out = [];
		for (let i in cout) {
			let region_pricing = calculateOverrides(cout[i], overrides);
			out.push(region_pricing);
		}
		return out;
	};

	const calculateOverrides = (region_prices, overrides) => {
		let override_region = overrides.find((r) => r.region_id === region_prices.region_id);
		if (override_region) {
			if (override_region.official_fee !== null) {
				region_prices.official_fee = override_region.official_fee;
				region_prices.official_fee_override = true;
			}
			if (override_region.professional_fee !== null) {
				region_prices.professional_fee = override_region.professional_fee;
				region_prices.professional_fee_override = true;
			}
			if (override_region.translation_fee !== null) {
				region_prices.translation_fee = override_region.translation_fee;
				region_prices.translation_fee_override = true;
			}
			region_prices.total = region_prices.translation_fee + region_prices.professional_fee + region_prices.official_fee;
		}
		return region_prices;
	};

	const rulesTransform = (data) => {
		return data;
	};

	const calculateTotalVisible = (data) => {
		if (!data) return 0;
		let total = 0;
		for (let i in data) {
			if (!data[i].is_visible) continue;
			total += data[i].total;
		}
		return total;
	};

	const calculateGrandTotal = (data) => {
		if (!data) return 0;
		let total = 0;
		for (let i in data) {
			total += data[i].total;
		}
		return total;
	};

	const handleQuoteCompleteCheckBox = async () => {
		let d = priceTableData[estimates[0].id];
		let r = [];
		if (completeQuote) {
			setCompleteQuote(false);
			// Iterating through the data and marking all regions as uncompleted
			for (let i in d) {
				d[i].complete = false;
				r.push(d[i].region_id);
			}
			setShowLoader(true);
			try {
				await Quotes.UpdateCompletedRegionsById(id, []);
				setData({ ...data, completed_regions: [] });
				setShowLoader(false);
			} catch (error) {
				setShowLoader(false);
				console.log('error', error);
			}
		} else {
			setCompleteQuote(true);
			// Iterating through the data and marking the uncompleted regions as completed
			for (let i in d) {
				if (!d[i].complete) {
					d[i].complete = true;
				}
				r.push(d[i].region_id);
			}
			try {
				setShowLoader(true);
				await Quotes.UpdateCompletedRegionsById(id, [...r]);
				setData({ ...data, completed_regions: r });
				setShowLoader(false);
			} catch (error) {
				setShowLoader(false);
				console.log('error', error);
			}
		}
	};

	const handleRegionCompleteCheckbox = async (e) => {
		const { region_id, estimate_id } = e.target.closest('tr').dataset;
		let d = priceTableData[estimate_id];
		// modify only the last estimate
		if (estimates[0].id !== estimate_id) return;
		try {
			for (let i in d) {
				if (d[i].region_id === region_id) {
					if (d[i].complete) {
						d[i].complete = false;
						await Quotes.UpdateCompletedRegionsById(id, [...data.completed_regions.filter((r) => r !== region_id)]);
						setData({ ...data, completed_regions: [...data.completed_regions.filter((r) => r !== region_id)] });
					} else {
						d[i].complete = true;
						await Quotes.UpdateCompletedRegionsById(id, [...data.completed_regions, region_id]);
						setData({ ...data, completed_regions: [...data.completed_regions, region_id] });
					}
				}
				setPriceTableData((prevState) => {
					return { ...prevState, [estimate_id]: d };
				});
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	const getDocuments = async () => {
		return await Documents.getByCase(id);
	};

	const submitCurrency = (currency) => {
		setUsedCurrency(currency);
		refreshEstimatesData(currency);
	};

	const toggleRegionVisibility = (e, region_id) => {
		let estimate_id = e.target.closest('tr').dataset.estimate_id;
		let latestEstimate = estimates[0];
		if (priceTableData[estimate_id]) {
			// ability to make changes only on latest estimate
			if (latestEstimate.id !== estimate_id) return;
			let data = priceTableData[estimate_id].map((d) => {
				if (d.region_id === region_id) {
					d.is_visible = d.is_visible ? 0 : 1;
				}
				return d;
			});
			setPriceTableData((prevState) => {
				return { ...prevState, [estimate_id]: data };
			});
		}
	};

	const printPdfReport = async (e) => {
		e.preventDefault();
		const eid = e.target.closest('details').dataset.id;
		EventLoggerEvents.EventLogger({ event: `Downloaded Quote - Quote Details`, data: { user: user, estimate_id: eid } });

		try {
			await Reports.DownloadEstimateReport(eid, id, {});
		} catch (error) {
			console.log(error);
		}
	};

	const printPdfProforma = async (e) => {
		e.preventDefault();
		const eid = e.target.closest('details').dataset.id;
		try {
			await Reports.DownloadEstimateProformaReport(eid, id, { currency: e.target.dataset.currency });
		} catch (error) {
			console.log(error);
		}
	};

	const printXlsxReport = async (e) => {
		e.preventDefault();
		try {
			const eid = e.target.closest('details').dataset.id;
			await Reports.DownloadXlsxEstimateReport(eid, id);
		} catch (error) {
			console.log('error', error);
		}
	};

	const regionFilterData = (pricing_data) => {
		let regions = [{ label: 'All', value: '' }];
		for (let i in pricing_data) {
			regions.push({ label: pricing_data[i].region_name, value: pricing_data[i].region_id });
		}
		return regions;
	};

	const calculatePublishedTime = (estimate) => {
		if (estimate.published) {
			let published = new Date(estimate.published);
			let now = new Date();
			let time = Math.abs(published - now) / 3600000;
			if (time < 2) {
				return true;
			}
		}
		return false;
	};

	const showModifyExchangeRates = () => {
		setModifyExchangeRates(true);
	};

	const closesetModifyExchangeRates = () => {
		setModifyExchangeRates(false);
	};

	const setQuoteExchangeRatesData = (fx, date) => {
		setData({ ...data, exchange_rate_padding: fx, exchange_rate_date: date });
	};

	const openInstructModal = () => {
		history.push(`/quotes/${id}/revisions/instruct`);
	};

	return (
		<div className="quote-details-view-revisions">
			<LoaderOverlay showLoader={showLoader} />
			<div className="quote-details-view-revisions__qute-info">
				{data.additional_info ?
					<div className="quote-details-view-revisions__qute-info-container">
						<div className="quote-info-image-container">
							<img src={ICON_INFO} alt="" />
						</div>
						There are notes from the client in Additional Information
					</div>
				:	null}

				{documents && documents.length > 0 ?
					<div className="quote-details-view-revisions__qute-info-container">
						<div className="quote-info-image-container">
							<img src={ICON_INFO} alt="" />
						</div>
						There are attachments in Additional Information
					</div>
				:	null}
			</div>
			<div className="quote-details-view-revisions__header">
				<div className="quote-details-view-revisions__header__select-container">
					<Select label="Filing Fees" options={pricingLevels} name="professional_fee" value={professionalFee} onChange={professionalFeeChange} />
					<Select label="Translation Fees" options={pricingLevels} name="translation_fee" value={translationFee} onChange={translationFeeChange} />
				</div>
				<div className="quote-details-view-revisions__header__checkbox-container">
					<Checkbox label="Quote Complete" name="quote_complete" onChange={handleQuoteCompleteCheckBox} checked={completeQuote} error={!completeQuote && true} />
				</div>
				<div className="quote-details-admin-panel-revision__content-stripe-body-header-button">
					<Button theme="blurple" customClassName="quote-details-admin-panel-revision__instruct-btn" onClick={openInstructModal}>
						Instruct
					</Button>
					<div theme="blurple" className="quote-settings">
						<div className="quote-settings-icon-container">
							<img src={ICON_GEAR_WHITE} />
						</div>
						Quote Settings
						<div className="quote-settings-icon-container icon-sort-down">
							<img src={ICON_ARROW_DOWN} />
						</div>
						<ul className="quote-settings-button-container">
							<li className="quote-settings-actions-menu__list-item">
								<div>
									<img src={ICON_DUPLICATE_QUOTE} />
								</div>
								<span>Duplicate Quote</span>
							</li>
							<li className="quote-settings-actions-menu__list-item" onClick={showModifyCurrencyModal}>
								<div>
									<img src={ICON_CONVERT_CURRENCY} />
								</div>
								<span>Convert Currency</span>
							</li>
							<li className="quote-settings-actions-menu__list-item" onClick={showModifyExchangeRates}>
								<div>
									<img src={ICON_EXCHANGE_RATES} />
								</div>
								<span>Change Exchange Rate</span>
							</li>
							<li className="quote-settings-actions-menu__list-item">
								<Checkbox label="Use Custom Rules" name="region_complete" id="selectAll" checked={true} />
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="quote-details-view-content__body">
				{estimates
					?.sort((a, b) => (new Date(a._created).getTime() > new Date(b._created).getTime() ? -1 : 1))
					.map((e, i) => {
						return (
							<details className="quote-details-view-revisions__collapsible" onToggle={estimateToggle} data-id={e.id} key={e.id}>
								<summary className={`quote-details-view-revisions__collapsible-summary ${activeIndex === e.id ? 'active' : ''}`}>
									<RoleBox roles={INTERNAL_ROLES}>
										<div className={`quote-details-view-revisions__collapsible-summary-status-indicator ${e.status === ESTIMATE_STATUS.PUBLISHED ? 'published-status' : ''}`}></div>
									</RoleBox>

									<span className="summary-arrows">
										{activeIndex === e.id ?
											<img src={ICON_ARROW_UP_BLURPLE} />
										:	<img src={ICON_ARROW_DOWN_BLURPLE} />}
									</span>
									<span>
										<img src={ICON_CALENDAR} />
										<AppDate>{e._created}</AppDate>
									</span>
									<span>
										<img src={ICON_CLOCK} /> {toLocaleTimeString(e._created)}
									</span>
									<span className="summary-pricing-level">
										<img src={ICON_PRICING_LEVEL} />
										Filing Fees: {pricingLevels.find((pl) => pl.value === e.professional_fee_pricing_level_id)?.label}
									</span>
									<span className="summary-pricing-level">
										<img src={ICON_PRICING_LEVEL} />
										Translation Fees: {pricingLevels.find((pl) => pl.value === e.translation_fee_pricing_level_id)?.label}
									</span>
									<span className="quote-summary__icons">
										<span data-currency={'EUR'} onClick={printPdfProforma}>
											&#x20AC;
										</span>
										<span data-currency={'USD'} onClick={printPdfProforma}>
											&#x24;
										</span>
										{e.stop_rules.length > 0 ?
											<img src={ICON_STOP_RULE} title="Stop Rules Exists" />
										:	null}
										{e.failed_rules && e.failed_rules.length > 0 ?
											<img src={ICON_FAIL_RULE} title="Failed Rules Exists" />
										:	null}
										<img src={ICON_PDF_EXPORT} onClick={printPdfReport} />
									</span>
									<div className="quote-summary__actions">
										<div className="quote-summary__button">
											<img src={ICON_ACTIONS_MENU_BLURPLE} />
										</div>
										<ul className="quote-summary__actions-items">
											<RoleBox roles={INTERNAL_ROLES}>
												{estimates[0].id === e.id && (e.status === ESTIMATE_STATUS.DRAFT || calculatePublishedTime(e)) ?
													<li className="quote-summary__actions-list-item" onClick={showPublishModal}>
														<div>
															<img src={ICON_PUBLISH} />
														</div>
														<span>{e.status === ESTIMATE_STATUS.DRAFT ? 'Publish this Estimate' : 'Unpublish this estimate'}</span>
													</li>
												:	null}
												{estimates[0].id === e.id ?
													<li className="quote-summary__actions-list-item" onClick={pushToCAS}>
														<div>
															<img src={ICON_PUSH_TO_SYSTEM} />
														</div>
														<span>Push to System</span>
													</li>
												:	null}
												<li className="quote-summary__actions-list-item" onClick={sendEmailToAE}>
													<div>
														<img src={ICON_EMAIL} />
													</div>
													<span>Send Email to AE</span>
												</li>
											</RoleBox>
											<li className="quote-summary__actions-list-item" onClick={showOverrideFeesModal} data-id={e.id}>
												<div>
													<img src={ICON_OVERRIDE_FEES} />
												</div>
												<span>Override Fees</span>
											</li>
											<li className="quote-summary__actions-list-item" onClick={printPdfReport}>
												<div>
													<img src={ICON_PDF} />
												</div>
												<span>Export PDF</span>
											</li>
											<li className="quote-summary__actions-list-item" onClick={printXlsxReport}>
												<div>
													<img src={ICON_EXCEL} />
												</div>
												<span>Export Excel</span>
											</li>
											<li className="quote-summary__actions-list-item">
												<div>
													<img src={ICON_DOWNLOAD} />
												</div>
												<span>Download Draft Quote</span>
											</li>
										</ul>
									</div>
								</summary>
								<div className="quote-details-view-revisions__collapsible-body">
									<>
										{e.failed_rules && e.failed_rules.length > 0 ?
											<div className="quote-details__failed-rules-container">
												<p>This quote failed the following general rules:</p>
												<ul>
													{e.failed_rules.map((r) => (
														<li>{QUOTE_VALIDATION_ERROR[r]}</li>
													))}
												</ul>
												<p>To see the failed calculation rules please see the rules table below</p>
											</div>
										:	null}
										{priceTableData[e.id] && ruleTableData[e.id]?.some((r) => r.rule_level === RULE_LEVEL.FIRM || r.rule_level === RULE_LEVEL.CASE) ?
											<div className="quote-details__custom-rules-container">
												<p>Custom rules exist on this estimate</p>
											</div>
										:	null}
									</>
									<DataTable columns={feesTableHeaders}>
										{priceTableData[e.id] ?
											priceTableData[e.id].map((pd) => {
												return (
													<tr className={pd.is_visible ? '' : 'grayed-out'} data-region_id={pd.region_id} data-estimate_id={e.id}>
														<DataTable.CountryCell code={pd.region_code}>{pd.region_name}</DataTable.CountryCell>
														<DataTable.Cell>{`${pd.firm_name ? pd.firm_name : 'Azami'}`}</DataTable.Cell>
														<DataTable.OverridePriceCell
															overrideIcon={pd.professional_fee_override}
														>{`${currencyIdToSymbol(usedCurrency)} ${formatNumberWithSeparator(pd.professional_fee, 0, 0)}`}</DataTable.OverridePriceCell>
														<DataTable.OverridePriceCell
															overrideIcon={pd.translation_fee_override}
														>{`${currencyIdToSymbol(usedCurrency)} ${formatNumberWithSeparator(pd.translation_fee, 0, 0)}`}</DataTable.OverridePriceCell>
														<DataTable.OverridePriceCell
															overrideIcon={pd.official_fee_override}
														>{`${currencyIdToSymbol(usedCurrency)} ${formatNumberWithSeparator(pd.official_fee, 0, 0)}`}</DataTable.OverridePriceCell>
														<DataTable.Cell>{`${currencyIdToSymbol(usedCurrency)} ${formatNumberWithSeparator(pd.total, 0, 0)}`}</DataTable.Cell>
														<DataTable.Cell>
															<Checkbox customClassName="table-row-checkbox" name="region_complete" checked={pd.complete} onChange={handleRegionCompleteCheckbox} />
														</DataTable.Cell>
														<DataTable.Cell>
															<div className="quote-details-view-revisions__toggle__region">
																<button className="grayed-out__region__toggle__btn" onClick={(e) => toggleRegionVisibility(e, pd.region_id)}>
																	<ToolTip>
																		<span>{pd.is_visible ? 'Hides region on PDF/Excel' : 'Unhides region on PDF/Excel'}</span>
																	</ToolTip>
																	<img src={pd.is_visible ? ICON_EYE_VISIBLE : ICON_EYE_NOT_VISIBLE} alt="" />
																</button>
															</div>
														</DataTable.Cell>
													</tr>
												);
											})
										:	null}

										<tr>
											<DataTable.Cell>{'Total'}</DataTable.Cell>
											<DataTable.Cell></DataTable.Cell>
											<DataTable.Cell></DataTable.Cell>
											<DataTable.Cell></DataTable.Cell>
											<DataTable.Cell></DataTable.Cell>
											<DataTable.Cell>{`${currencyIdToSymbol(usedCurrency)} ${formatNumberWithSeparator(calculateGrandTotal(priceTableData[e.id]), 0, 0)}`}</DataTable.Cell>
											<DataTable.Cell></DataTable.Cell>
											<DataTable.Cell></DataTable.Cell>
										</tr>
										<tr>
											<DataTable.Cell>{'Total Visible'}</DataTable.Cell>
											<DataTable.Cell></DataTable.Cell>
											<DataTable.Cell></DataTable.Cell>
											<DataTable.Cell></DataTable.Cell>
											<DataTable.Cell></DataTable.Cell>
											<DataTable.Cell>{`${currencyIdToSymbol(usedCurrency)} ${formatNumberWithSeparator(calculateTotalVisible(priceTableData[e.id]), 0, 0)}`}</DataTable.Cell>
											<DataTable.Cell></DataTable.Cell>
											<DataTable.Cell></DataTable.Cell>
										</tr>
									</DataTable>
									{/*  */}
									<div className="quote-revisions__rule-filter">
										<Input label="Search by name" name="name" value={filters.name} onChange={onFilterChange} />
										<Select
											label="Region"
											options={priceTableData[e.id] ? regionFilterData(priceTableData[e.id]) : [{ label: 'All', value: '' }]}
											name="region"
											value={filters.region}
											onChange={onFilterChange}
										/>
										<Select label="Fee Type" options={feeTypes} name="fee_type" value={filters.fee_type} onChange={onFilterChange} />
										<Select label="Rule Level" options={ESTIMATOR_RULE_LEVELS} name="rule_level" value={filters.rule_level} onChange={onFilterChange} />
									</div>
									<DataTable columns={rulesTableHeaders} customClassName="rules-table">
										{ruleTableData[e.id] ?
											ruleTableData[e.id]
												.filter((r) => {
													return (
														r.rule_name.toLowerCase().includes(filters.name.toLowerCase()) &&
														r.region_id.includes(filters.region) &&
														r.fee_type.includes(filters.fee_type) &&
														r.rule_level.includes(filters.rule_level)
													);
												})
												.map((r) => {
													let stopRule = e.stop_rules.find((sr) => sr === r.rule_id);
													let stopRuleClass = stopRule ? 'stop-rule' : '';
													let failedRuleClass = r.is_failed ? 'failed-rule' : '';
													let customRuleClass = r.rule_level === RULE_LEVEL.FIRM || r.rule_level === RULE_LEVEL.CASE ? 'custom-rule' : '';
													return (
														<tr key={r.id} className={`${stopRuleClass} ${failedRuleClass}`}>
															<DataTable.Cell customClassName={customRuleClass}>{r.rule_name}</DataTable.Cell>
															<DataTable.Cell customClassName="quote-revisions__rule-level-label">{r.rule_level.toLowerCase()}</DataTable.Cell>
															<DataTable.Cell>
																<LetterBadge theme="inverse" color={badgeColors[r.fee_type]}>
																	{r.fee_type}
																</LetterBadge>
															</DataTable.Cell>
															<DataTable.CountryCell code={r.region_code}>{r.region_name}</DataTable.CountryCell>
															<DataTable.Cell>{`${currencyIdToSymbol(usedCurrency)} ${formatNumberWithSeparator(roundUp(r.fee_value), 0, 0)}`}</DataTable.Cell>
															<DataTable.Cell>
																{stopRule ?
																	<img src={ICON_STOP_RULE} title="Stop Rule" />
																:	null}
																{r.is_failed ?
																	<img src={ICON_FAIL_RULE} title="Failed Rule" />
																:	null}
															</DataTable.Cell>
														</tr>
													);
												})
										:	null}
									</DataTable>
								</div>
							</details>
						);
					})}
			</div>
			<footer className="main-screen-section__details-content__footer quote-footer-a">
				<Button theme="azami-light-violet" className="quote-details__footer__btn-margin">
					Save As Draft
				</Button>
				<Button onClick={handleRequestQuote} disabled={requestQuoteBtnDisabled}>
					Request Quote
				</Button>
			</footer>
			{overridesModal ?
				<QuotesDetailsViewOverridesModal
					closeHandler={closeOverrideFeesModal}
					currency_symbol={currencyIdToSymbol(usedCurrency)}
					estimate_id={overridesModalEstimateId}
					last_estimate_id={estimates[0].id}
					caseData={data}
					pricingTransform={pricingTransform}
					priceTableData={priceTableData}
					setLastOverrideFees={setLastOverrideFees}
					lastOverrideFees={lastOverrideFees}
					setPriceTableData={setPriceTableData}
				></QuotesDetailsViewOverridesModal>
			:	null}
			{modifyCurrencyModal ?
				<QutesDetailsViewModifyCurrencyModal closeHandler={closeModifyCurrencyModal} selectedCurrency={usedCurrency} submitCurrency={submitCurrency}></QutesDetailsViewModifyCurrencyModal>
			:	null}
			{publishModal ?
				<QuoteDetailsViewRevisionsPublishModal
					closeHandler={closePublishModal}
					estimates={estimates}
					refreshEstimatesData={refreshEstimatesData}
					refreshQuoteStatus={props.refreshStatus}
					user={user}
					quoteID={id}
					caseNumber={data.case_number}
				></QuoteDetailsViewRevisionsPublishModal>
			:	null}
			{modifyExchangeRates ?
				<QuotesDetailsViewExchangeRatesModal
					closeHandler={closesetModifyExchangeRates}
					quoteID={id}
					setQuoteExchangeRatesData={setQuoteExchangeRatesData}
				></QuotesDetailsViewExchangeRatesModal>
			:	null}
		</div>
	);
};

export default QuoteDetailsViewQuoteRevisions;
